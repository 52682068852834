import React, { useState } from 'react';
import unseeicon from '../../assets/images/unseeicon.svg';
import seeicon from '../../assets/images/seeicon.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../store/enterpriseFeature/updatepasswordEnterpriseSlice';
import { deactivateUser } from '../../store/enterpriseFeature/deactiveuserSlice';
const EnterpriseChangePassword = () => {
    const [passwords, setPasswords] = useState({
        current: '',
        new: '',
        confirm: '',
    });
    const [showPassword, setShowPassword] = useState({
        current: false,
        new: false,
        confirm: false,
    });
    const [passwordError, setPasswordError] = useState(''); // State for mismatch error

    const dispatch = useDispatch();
    const { loading, success, error, message } = useSelector((state) => state.updatepasswordenterprise);


    const { loading: deactivateLoading, error: deactivateError, success: deactivateSuccess, message: deactivateMessage } = useSelector((state) => state.deactivateUser);


  // التعامل مع عملية تعطيل الحساب
  const handleDeactivateAccount = () => {
    dispatch(deactivateUser());
  };



    const togglePasswordVisibility = (field) => {
        setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setPasswords((prev) => ({ ...prev, [id]: value }));

          // Clear error when user starts typing again and password will match 
          if (id === 'confirm' || id === 'new') {
            setPasswordError('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (passwords.new !== passwords.confirm) {
            setPasswordError('New password and confirm password do not match');
            return;
        }

        const payload = {
            new_password: passwords.new,
            confirm_password: passwords.new,
            old_password: passwords.current,
        };

        console.log('Submitting payload to change password:', payload);

        dispatch(updatePassword(payload))
            .unwrap()
            .then(() => {
                console.log('Password updated successfully');
            })
            .catch((error) => {
                console.error('Error updating password:', error);
            });
    };

    return (
        <div className="ChangeInstructorPassword_Container" style={{ position: 'relative', marginBottom: '25px' }}>
            <h2 className="ChangeInstructorPassword_Head">Change your Password</h2>

            <form className="ChangeInstructorPassword_Form" onSubmit={handleSubmit}>
                {/* Current Password */}
                <div className="InputGroup">
                    <label htmlFor="current">Current password</label>
                    <div className="PasswordInput">
                        <input
                            type={showPassword.current ? 'text' : 'password'}
                            id="current"
                            placeholder="Password"
                            value={passwords.current}
                            onChange={handleInputChange}
                            required
                        />
                        <button
                            type="button"
                            className="ToggleVisibility"
                            onClick={() => togglePasswordVisibility('current')}
                        >
                            {showPassword.current ? <img src={seeicon} alt="seeicon" /> : <img src={unseeicon} alt="unseeicon" />}
                        </button>
                    </div>
                </div>

                {/* New Password */}
                <div className="InputGroup">
                    <label htmlFor="new">New password</label>
                    <div className="PasswordInput">
                        <input
                            type={showPassword.new ? 'text' : 'password'}
                            id="new"
                            placeholder="New password"
                            value={passwords.new}
                            onChange={handleInputChange}
                            required
                        />
                        <button
                            type="button"
                            className="ToggleVisibility"
                            onClick={() => togglePasswordVisibility('new')}
                        >
                            {showPassword.new ? <img src={seeicon} alt="seeicon" /> : <img src={unseeicon} alt="unseeicon" />}
                        </button>
                    </div>
                </div>

                {/* Confirm New Password */}
                <div className="InputGroup">
                    <label htmlFor="confirm">Confirm new password</label>
                    <div className="PasswordInput">
                        <input
                            type={showPassword.confirm ? 'text' : 'password'}
                            id="confirm"
                            placeholder="Confirm new password"
                            value={passwords.confirm}
                            onChange={handleInputChange}
                            required
                        />
                        <button
                            type="button"
                            className="ToggleVisibility"
                            onClick={() => togglePasswordVisibility('confirm')}
                        >
                            {showPassword.confirm ? <img src={seeicon} alt="seeicon" /> : <img src={unseeicon} alt="unseeicon" />}
                        </button>
                    </div>
                </div>

                <div className="ChangeInstructorPassword_Container_buttons">
                    <div className="ForgotPassword">
                        <Link to="/forgot-password-enterprise">Forgot your password?</Link>
                    </div>

                    <button type="submit" className="SaveChangesButton" disabled={loading}>
                        {loading ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            'Save changes'
                        )}
                    </button>
                </div>
            </form>
            {passwordError && <p className="ErrorMessage">{passwordError}</p>} {/* Display mismatch error */}

            {/* Feedback Messages */}
            {error && (
                <p className='ErrorMessage'>
                    {typeof error === 'string' ? error : JSON.stringify(error.msg)}
                </p>
            )}
            {success && message && (
                <p className='SuccessMessage'>
                    {typeof message === 'string' ? message : JSON.stringify(message.msg)}
                </p>
            )}
            {/* end Feedback Messages */}

            {/* DeActive Enterprise Account */}
            <div style={{ display: 'none', justifyContent: 'center', paddingBottom: '10px' }}>
  <div className='Enterprise_Delete_Account'>
    {deactivateLoading ? (
      <p>Deleting account...</p>  // Check if this JSX element is closed properly
    ) : (
      <div onClick={handleDeactivateAccount}>Delete my Lndex account</div>
    )}
  </div>
</div>
        </div>
    );
};

export default EnterpriseChangePassword;
