import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk to fetch enterprise course by ID
export const fetchEnterpriseCourseById = createAsyncThunk(
  'enterpriseCourseID/fetchEnterpriseCourseById',
  async (courseId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken');
      const response = await axios.get(`${URL}/enterprise/course/${courseId}`, {
        headers: { token },
      });
      console.log('API Response:', response.data); // Log the API response
      return response.data.data.course; // Ensure this matches the API structure
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

const enterpriseCourseIDSlice = createSlice({
  name: 'enterpriseCourseID',
  initialState: {
    course: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnterpriseCourseById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEnterpriseCourseById.fulfilled, (state, action) => {
        state.loading = false;
        state.course = action.payload; // Ensure this matches the API response
        console.log('Redux State Updated:', state.course); // Log the updated state
      })
      .addCase(fetchEnterpriseCourseById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.error('Redux State Error:', action.payload); // Log the error
      });
  },
});

export default enterpriseCourseIDSlice.reducer;