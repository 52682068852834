// Map.js
import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../config';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const Map = ({ lat, lng, zoom = 15 }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY // استبدلها بمفتاحك الحقيقي
  });

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  const latFloat = parseFloat(lat);
  const lngFloat = parseFloat(lng);

  if (isNaN(latFloat) || isNaN(lngFloat)) {
    console.error('❌ Invalid lat/lng values:', { lat, lng });
    return <div>Invalid location coordinates provided.</div>;
  }

  const center = { lat: latFloat, lng: lngFloat };
  const markerPosition = { lat: latFloat, lng: lngFloat };

  console.log('✅ center:', center);
  console.log('📍 Marker position (final):', markerPosition);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
    >
      <Marker position={markerPosition} />
    </GoogleMap>
  );
};

export default Map;
