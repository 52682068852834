import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';



// Async thunk to submit a review
export const submitReview = createAsyncThunk(
  'review/submitReview',
  async ({ course_id, rate, review, is_enterprise }, { rejectWithValue }) => {
    try {
        const token = Cookies.get('authToken');
      const response = await axios.post(
        `${URL}/user-rate-course`,
        { course_id, rate, review, is_enterprise },
        {
          headers: {
           token
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

// Initial state
const initialState = {
  loading: false,
  error: null,
  success: false,
  reviewData: null,
};

// Create the slice
const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    resetReviewState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.reviewData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle pending state
      .addCase(submitReview.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      // Handle fulfilled state
      .addCase(submitReview.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.reviewData = action.payload;
      })
      // Handle rejected state
      .addCase(submitReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to submit review';
      });
  },
});

// Export actions
export const { resetReviewState } = reviewSlice.actions;

// Export the reducer
export default reviewSlice.reducer;