import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';
import Cookies from 'js-cookie';

// إنشاء thunk لجلب جميع الفروع
export const fetchAllBranches = createAsyncThunk(
  'allBranches/fetchAllBranches',
  async (_, { rejectWithValue }) => {
    const token = Cookies.get("entoken");
    try {
      const response = await axios.get(`${URL}/enterprise/branches/all-branches`, {
        headers: { token }
      });
      // نتأكد من نجاح الطلب عن طريق التحقق من response.data.status
      if (response.data.status) {
        // استخراج بيانات الفروع من الكائن
        return response.data.data.branch;
      } else {
        return rejectWithValue(response.data.msg || 'حدث خطأ أثناء جلب الفروع.');
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || 'حدث خطأ أثناء جلب الفروع.'
      );
    }
  }
);

const allBranchesSlice = createSlice({
  name: 'allBranches',
  initialState: {
    branches: [],
    status: 'idle', // idle | loading | succeeded | failed
    error: null
  },
  reducers: {
    // يمكن إضافة إجراءات تزامنية هنا إن لزم الأمر
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBranches.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAllBranches.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.branches = action.payload;
      })
      .addCase(fetchAllBranches.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default allBranchesSlice.reducer;
