import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { URL } from '../../../config';
import Loading from '../../../component/Loading/Loading';

const SubcityDropdown = ({ selectedSubcity, onSubcityChange, cityId, language = 'en' }) => {
  const [subcities, setSubcities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubcities = async () => {
      try {
        const response = await axios.get(`${URL}/subcities-city/${cityId}`);
        console.log('Subcities API Response:', response.data);

        // Format the subcities to display either `name_en` or `name_ar`
        const formattedSubcities = response.data.data.subCities.map(subcity => ({
          id: subcity.id,
          name: language === 'ar' ? subcity.name_ar : subcity.name_en,
        }));

        setSubcities(formattedSubcities);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (cityId) { // Only fetch if a city is selected
      fetchSubcities();
    } else {
      setLoading(false); // If no city selected, stop loading
    }
  }, [cityId, language]); // Refetch data if cityId or language changes

  const handleSubcityChange = (e) => {
    onSubcityChange(e.target.value);
  };

  if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="subcity-dropdown">
      <select
        value={selectedSubcity}
        onChange={handleSubcityChange}
        className="subcity-select"
        style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
      >
        <option value="" disabled>
          Select the subcity
        </option>
        {subcities.map(subcity => (
          <option key={subcity.id} value={subcity.id}>
            {subcity.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SubcityDropdown;