import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; // Import framer-motion
import StarCompact from '../../assets/images/review/StarCompact.svg';
import StarWhite from '../../assets/images/review/Starwhite.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../store/userFeature/userDataSlice';
import './Review.css';
import { submitReview } from '../../store/Review/ReviewSlice'
import Lines from '../../assets/images/Line.svg';
import { fetchCourseById } from '../../store/UserCoursesID/CourseIDSlice';
import { fetchEnterpriseCourseById } from '../../store/UserCoursesID/EnterpriseCourseIDSlice';

const Review = ({ courseId, isEnterprise }) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [showCommentField, setShowCommentField] = useState(false);
  const [comment, setComment] = useState('');
  const [showButtons, setShowButtons] = useState(false);

  const { loading: userLoading, error: userError, userData } = useSelector((state) => state.userData);
  const user = userData?.data?.user;

  const { loading, error, success, reviewData } = useSelector((state) => state.Review);
  const { course, loading: courseLoading, error: courseError } = useSelector(
    (state) => state.GetcourseId || {}
  );

  const { course: enterpriseCourse, loading: enterpriseLoading, error: enterpriseError } = useSelector(
    (state) => state.enterpriseCourseID
  );
  
  console.log('Redux State - Enterprise Course:', enterpriseCourse);
  // Determine which course data to use based on isEnterprise
  const currentCourse = isEnterprise === 1 ? enterpriseCourse : course;
  const currentLoading = isEnterprise === 1 ? enterpriseLoading : courseLoading;
  const currentError = isEnterprise === 1 ? enterpriseError : courseError;

  console.log('tests Get Enterprise to test : ', currentCourse ,enterpriseCourse)

  useEffect(() => {
    if (courseId) {
      if (isEnterprise === 1) {
        dispatch(fetchEnterpriseCourseById(courseId));
      } else {
        dispatch(fetchCourseById(courseId));
      }
    }
  }, [success, dispatch, courseId, isEnterprise]);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
    setShowCommentField(true);
    setShowButtons(true);
  };

  const handleSaveRate = () => {
    const payload = {
      course_id: courseId,
      rate: rating,
      review: comment,
      is_enterprise: isEnterprise,
    };
    dispatch(submitReview(payload));
    if (isEnterprise === 1) {
      dispatch(fetchEnterpriseCourseById(courseId));
    } else {
      dispatch(fetchCourseById(courseId));
    }
  };

  useEffect(() => {
    if (success) {
      setRating(0);
      setComment('');
      setShowCommentField(false);
      setShowButtons(false);
      console.log('Review submitted successfully:', reviewData);
    }
  }, [success, reviewData]);

  const handleCancel = () => {
    setRating(0);
    setComment('');
    setShowCommentField(false);
    setShowButtons(false);
  };

  const LoadingSpinner = () => (
    <motion.div
      className='loading-spinner'
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
    >
      ⏳
    </motion.div>
  );

  const LoadingAnimation = () => (
    <motion.div
      className="loading-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="loading-spinner"
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      />
    </motion.div>
  );

  return (
    <>
    
      <div className='Rview_getAll'>
        {userLoading ? (
          <LoadingSpinner />
        ) : currentCourse?.reviews && currentCourse.reviews.length > 0 ? (
          currentCourse.reviews.map((review, index) => (
            <div key={review.id} className='Review_user_info' style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '350px' }}>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <div className='user-image-container'>
                    <motion.img
                      src={review.user?.image}
                      alt='UserImage'
                      className='user-image'
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }}
                    />
                  </div>
                  <div className='Review_user_Container'>
                    <div className='user-name'>{review.user?.first_name} {review.user?.last_name}</div>
                    <div className='user-role'>Student</div>
                  </div>
                </div>
                <div className='review-stars'>
                  {[...Array(5)].map((_, starIndex) => (
                    <img
                      key={starIndex}
                      src={starIndex < review.rating ? StarCompact : StarWhite}
                      alt='Star'
                      className={`star-icon ${starIndex < review.rating ? 'active' : ''}`}
                    />
                  ))}
                </div>
              </div>
              <div className='Review_user_Comment'>
                {review.review}
              </div>
              <img src={Lines} alt='Lines' style={{ width: '25vw' }} />
            </div>
          ))
        ) : (
          <motion.div
            className='no-reviews-message'
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            No reviews available
          </motion.div>
        )}
      </div>

      {currentCourse?.is_buy === 1 ?
        <div className='ReviewAndComment'>
          {userLoading && <LoadingAnimation />}

          <div className='Review_Design'>
            {!userLoading && user && (
              <div className='Review_user_info'>
                <div className='user-image-container'>
                  <motion.img
                    src={user?.image}
                    alt='UserImage'
                    className='user-image'
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  />
                </div>
                <div className='Review_user_Container'>
                  <div className='user-name'>{user?.first_name} {user?.last_name}</div>
                  <div className='user-role'>Student</div>
                </div>
              </div>
            )}

            {!userLoading && (
              <div className='star-rating'>
                {[...Array(5)].map((_, index) => {
                  const starValue = index + 1;
                  return (
                    <motion.img
                      key={index}
                      src={starValue <= rating ? StarCompact : StarWhite}
                      alt={`Star ${starValue}`}
                      onClick={() => handleStarClick(starValue)}
                      style={{ cursor: 'pointer', marginRight: '5px' }}
                      whileHover={{ scale: 1.2 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{ type: 'spring', stiffness: 300 }}
                    />
                  );
                })}
              </div>
            )}
          </div>

          {showCommentField && (
            <motion.div
              className='comment-field'
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <textarea
                placeholder='Write your review...'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows={4}
                style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              />
            </motion.div>
          )}

          {showButtons && (
            <motion.div
              className='rating-buttons'
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <button onClick={handleSaveRate} disabled={loading}>
                {loading ? 'Submitting...' : 'Save Rate'}
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </motion.div>
          )}
        </div>
        :
        ''
      }

      {error && (
        <motion.div
          initial={{ opacity: 0, scale: 0.7, rotate: -10 }}
          animate={{
            opacity: 1,
            scale: 1,
            rotate: 0,
            color: "#ff6347",
            transition: {
              duration: 1,
              ease: "easeOut",
              type: "spring",
              stiffness: 120,
            },
          }}
          whileHover={{
            scale: 1,
            color: "#ff4500",
            transition: { duration: 0.3 }
          }}
          exit={{
            opacity: 0,
            scale: 0.2,
            transition: { duration: 0.5 }
          }}
          className="message-content"
          style={{ marginTop: '15px' }}
        >
          {error.msg}
        </motion.div>
      )}
    </>
  );
};

export default Review;