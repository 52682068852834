import React, { useState } from 'react';
import DeleteIcon from '../../assets/images/DeleteCourse.svg';

const ContentComponent = ({ contentData, setContentData }) => {
  const [newContent, setNewContent] = useState({ title: '', details: '' });
  const [errors, setErrors] = useState({ title: '', details: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContent((prevContent) => ({
      ...prevContent,
      [name]: value,
    }));

    // Remove error when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? '' : prevErrors[name],
    }));
  };

  const handleAddContent = (event) => {
    event.preventDefault();

    // Validate fields
    const newErrors = {
      title: newContent.title.trim() ? '' : 'Title is required.',
      details: newContent.details.trim() ? '' : 'Details are required.',
    };

    setErrors(newErrors);

    if (newErrors.title || newErrors.details) {
      return;
    }

    // Add content if valid
    setContentData((prevContent) => [...prevContent, newContent]);
    setNewContent({ title: '', details: '' });
  };

  const handleRemoveContent = (index) => {
    setContentData((prevContent) => prevContent.filter((_, i) => i !== index));
  };

  return (
    <div className="faqs-container">
      <div className="InstructorCreateCourse_form_Section_title">Content Section <span className='Mandatory_star'>*</span></div>
      <div className="faqs-form">
        <input
          type="text"
          name="title"
          placeholder="Enter title"
          value={newContent.title}
          onChange={handleInputChange}
          className="faq-input"
          maxLength={255}
        />
        {errors.title && <p style={{color:'red'}}>{errors.title}</p>}

        <textarea
          name="details"
          placeholder="Enter details"
          value={newContent.details}
          onChange={handleInputChange}
          className="faq-textarea"
          maxLength={255}
        />
        {errors.details && <p style={{color:'red'}}>{errors.details}</p>}

        <div className="faq-component-container">
          <button onClick={handleAddContent} className="faq-add-button">
            + Add New Content
          </button>
        </div>
      </div>
      <ul className="faqs-list">
        {contentData.map((content, index) => (
          <li key={index} className="faq-item">
            <div>
              <strong>Title:</strong>{' '}
              {content.title.length > 100 ? `${content.title.substring(0, 80)}...` : content.title}
              <br />
              <strong>Details:</strong> {content.details}
            </div>
            <img
              src={DeleteIcon}
              onClick={() => handleRemoveContent(index)}
              className="faq-delete-icon"
              alt="Delete"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContentComponent;
