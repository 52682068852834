import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { URL } from '../../../config';
import Loading from '../../../component/Loading/Loading';

const CountryDropdown = ({ selectedCountry, onCountryChange, language = 'en' }) => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${URL}/countries`);
        console.log('API Response:', response.data);

        // Format the countries to display either `name_en` or `name_ar`
        const formattedCountries = response.data.data.countries.map(country => ({
          id: country.id,
          name: language === 'ar' ? country.name_ar : country.name_en,
        }));

        setCountries(formattedCountries);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, [language]); // Refetch data if the language changes

  const handleCountryChange = (e) => {
    onCountryChange(e.target.value);
  };

  if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="country-dropdown">
      <select
        value={selectedCountry}
        onChange={handleCountryChange}
        className="country-select"
        style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
      >
        <option value="" disabled>
          Select the country
        </option>
        {countries.map(country => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountryDropdown;