import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { URL } from '../../../config';
import Loading from '../../../component/Loading/Loading';
import Cookies from 'js-cookie';

const BranchesDropDown = ({ selectedBranch, onBranchChange, language = 'en' }) => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
         const token = Cookies.get('entoken');
        const response = await axios.get(`${URL}/enterprise/branches/all-branches`, {
          headers: {
              token
          }
        });
        console.log('API Response:', response.data);

        const formattedBranches = response.data.data.branch.map(branch => ({
          id: branch.id,
          name: branch.branch_name
        }));

        setBranches(formattedBranches);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBranches();
  }, []);

  const handleBranchChange = (e) => {
    onBranchChange(e.target.value);
  };

  if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="branch-dropdown">
      <select
        value={selectedBranch}
        onChange={handleBranchChange}
        className="branch-select"
        style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
      >
        <option value="" disabled>
          Select a branch
        </option>
        {branches.map(branch => (
          <option key={branch.id} value={branch.id}>
            {branch.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BranchesDropDown;
