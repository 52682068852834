import React from 'react'
import './coursepurchases.css'
import purchasesicon from '../../assets/images/purchasesicon.svg'


const coursepurchases = () => {
  return (
    <div className='coursepurchases_cointainer'>
        <img style={{width:'60px'}} src={purchasesicon} alt='purchasesicon'/>    
        <div className='coursepurchases_Sec1'><span style={{color:'#3F52FC'}}>25</span>:purchases</div>   

    </div>
  )
}

export default coursepurchases