import { URL } from '../../config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

// Async thunk to handle the API call for adding a course to the cart
export const addCourseToCart = createAsyncThunk(
  'cart/addCourseToCart',
  async ({ courseId, isEnterprise }, { rejectWithValue }) => {
    try {
      const authToken = Cookies.get('authToken');
      const config = {
        headers: {
          token: authToken,
        },
      };
      const response = await axios.post(
        `${URL}/user-courses-atc`,
        {  
          course_id: courseId,
          is_enterprise: isEnterprise , 
        }, // Send the course_id in the request body
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the Redux slice for managing the cart state
// Create the Redux slice for managing the cart state
const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    adding: false,
    error: null,
    success: false,
    courseData: null, // To hold course data if needed
    message: null,
  },
  reducers: {
    resetCartState(state) {
      state.adding = false;
      state.error = null;
      state.success = false;
      state.courseData = null;
      state.message = null; // Reset message on state reset
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCourseToCart.pending, (state) => {
        state.adding = true;
        state.error = null;
        state.success = false;
        state.message = null; // Clear any previous messages
      })
      .addCase(addCourseToCart.fulfilled, (state, action) => {
        state.adding = false;
        state.success = true;
        state.error = null;
        state.courseData = action.payload.data?.course || null; // Safely access `data.course`
        state.message = action.payload.msg || 'Course added to cart successfully'; // Fallback message
      })
      .addCase(addCourseToCart.rejected, (state, action) => {
        state.adding = false;
        state.success = false;
        state.error = action.payload?.msg || 'Error adding course to cart'; // Fallback error message
        state.message = state.error; // Set error message
      
      });
  },
});

// Export the actions
export const { resetCartState } = cartSlice.actions; // export this action to component 

export default cartSlice.reducer; // Export only the reducer here
