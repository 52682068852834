import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../store/featureAuth';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import PasswordInput from '../../component/PasswordInput/PasswordInput';

const ChangeYourPassword = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth); // Access loading and error states from Redux
  const navigate = useNavigate();
  
  // Local state for form inputs
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [validationError, setValidationError] = useState(''); // Local state for validation errors

  // Function to handle the password change
  const handleChangePassword = (e) => {
    e.preventDefault(); // Prevent form submission from refreshing the page

    // Validate new password and repeated password match
    if (newPassword !== repeatNewPassword) {
      setValidationError('New passwords do not match');
      return;
    }

    // Clear validation errors
    setValidationError('');

    // Dispatch the Redux thunk to change the password
    dispatch(changePassword({ old_password: currentPassword, new_password: newPassword }))
      .then(() => {
        // If successful, navigate to /home
        navigate('/home');
      })
      .catch((err) => {
        // Handle errors (if any)
        console.error('Error changing password:', err);
      });
  };

  return (
    <div className='ChangeYourPassword_Container'>
      {/* Form to handle password change */}
      <form onSubmit={handleChangePassword}>
        {/* Current Password Field */}
        <div className='Chang_Pass_Sec'>
          <div className='Chang_Pass_Sec_label'>Current password</div>
          <PasswordInput
            type='password'
            className='Sign_input'
            style={{ width: '100%' }}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>

        {/* New Password Field */}
        <div className='Chang_Pass_Sec'>
          <div className='Chang_Pass_Sec_label'>New password</div>
          <PasswordInput
            type='password'
            className='Sign_input'
            style={{ width: '100%' }}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>

        {/* Repeat New Password Field */}
        <div className='Chang_Pass_Sec'>
          <div className='Chang_Pass_Sec_label'>Repeat New password</div>
          <PasswordInput
            type='password'
            className='Sign_input'
            style={{ width: '100%' }}
            value={repeatNewPassword}
            onChange={(e) => setRepeatNewPassword(e.target.value)}
          />
        </div>

        {/* Button Section */}
        <div className='Chang_Pass_Buttons'>
          <div>
            {loading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <button className='Chang_Pass_Buttons_Save' type="submit" disabled={loading} style={{marginTop:'25px'}}>
                Save changes
              </button>
            )}
          </div>
        </div>

        {/* Display Validation or API Error */}
        {validationError && <p className='error-message'>{validationError}</p>}
        
        {/* Render the error message if it exists */}
        {error && (
          <p className='error-message'>
            {typeof error === 'object' ? error.msg || error.message : error}
          </p>
        )}
      </form>
    </div>
  );
};

export default ChangeYourPassword;