import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserShowCart } from '../../store/userFeature/showUserCartSlice';
import { removeCourseFromCart } from '../../store/userFeature/userDeleteFromCartSlice'
import './CartPage.css'
import ArrowLeftGuide from '../../assets/images/ArrowLeft.svg'
import { Link } from 'react-router-dom'
import Line from '../../assets/images/Line.svg'
import CourseImage from '../../assets/images/Course/cart_course_img.svg'
import ClockIcon from '../../assets/images/Course/cart_circle-clock.svg'
import ArabicIcon from '../../assets/images/Course/cart_volume.svg'
import DeleteIcon from '../../assets/images/Course/cart_Delete.svg'
import ExitIcon from '../../assets/images/Course/ExitIcon.svg'
import cartckeckout from '../../assets/images/Course/cart_shoping_checkout.svg'
import ArrowCheckout from '../../assets/images/Course/cart_arrow_checkout.svg'
import LineCheckout from '../../assets/images/Course/CheckoutLine.svg'
import DiscountIcon from '../../assets/images/Course/discount-percent-checkout.svg'
import Loading from '../../component/Loading/Loading';
import { motion } from 'framer-motion';
import { initiatePayment } from '../../store/payment/PaymentSlice';


const CartPage = () => {
  const [showdelete, setShowdelete] = useState(false);
  const [message, setMessage] = useState("");  // State to store the success or error message
  const removing = useSelector((state) => state.userDeleteFromCart?.removing);
  const dispatch = useDispatch();

  const { iframeUrl, loading, error: paymentError } = useSelector((state) => state.payment);


  const { courses, status, error } = useSelector((state) => state.userShowCart);
  console.log(courses);

  const handleCheckout = () => {
    console.log('Checkout button clicked! Dispatching payment...');
    dispatch(initiatePayment());
  };
  
 // Redirect if iframeUrl is received
 useEffect(() => {
  if (iframeUrl) {
    console.log('Redirecting to payment URL:', iframeUrl);
    window.location.href = iframeUrl;
  }
}, [iframeUrl]);

  // استدعاء الـ thunk عند تحميل الـ component
  useEffect(() => {
    dispatch(fetchUserShowCart());
  }, [dispatch]);

  console.log(courses)
  const coursecarts = courses.items ;

  if (status === 'loading') {
    return <div><Loading /></div>;
  }
   
/*
  if (status === 'failed') {
    return <div>Error: {error?.message || JSON.stringify(error)}</div>;
  }
*/

const handleDelete = (courseId, enterpriseId) => {
  if (courseId) {
    const isEnterprise = !!enterpriseId; // Check if enterprise_id exists
    console.log('check Enterprise :',isEnterprise)
    // Dispatch the remove course action with the appropriate payload
    dispatch(removeCourseFromCart({ courseId, isEnterprise: enterpriseId }))
      .then(() => {
        // Show success message
        setMessage("Course deleted successfully!");
        // Reload the page after successful deletion
         window.location.reload();
      })
      .catch((error) => {
        // Show error message if deletion fails
        setMessage("Error deleting the course. Please try again.");
      });
    // Close the modal after deletion
    setShowdelete(false);
  }
};

     
  return (
    <div className='Cart_Container'>
      <div className='Cart_Part1'>
        <div className='Cart_Part1_sec1'>
          <div className='Cart_Part1_sec1_header'>
            <div className='Cart_Part1_sec1_header_R'>Shopping cart (  {courses?.total_quantity}  items)</div>
            <Link to='/home'><div className='Cart_Part1_sec1_header_R2'>Continue shopping</div></Link>
          </div>

          {/* Body */}
          {coursecarts && coursecarts.length > 0 ? (
          coursecarts?.map((course) => (
            <div className='Cart_Part1_sec1_body' key={course.id}>
              <div className='Cart_Part1_sec1_body_course'>
                <div className='Cart_Part1_sec1_body_course_part1'>
                  <img src={course?.course?.image} alt='CourseImage' className='courseimage_Cart'/>
                  <div className='Cart_Part1_sec1_body_course_part1_info'>
                    <div className='Cart_Part1_sec1_body_course_part1_coursename'>{course?.course?.title} </div>
                    <div className='Cart_Part1_sec1_body_course_part1_instructorname'>{course?.course?.instructor?.name}</div>
                    <div className='Cart_Part1_sec1_body_course_part1_clock'>
                      <img src={ClockIcon} alt='ClockIcon'/>
                      {course?.course?.course_hours} hours ({course?.course?.session_count} sessions)
                    </div>
                    <div className='Cart_Part1_sec1_body_course_part1_clock'>
                      <img src={ArabicIcon} alt='ArabicIcon'/>
                      {course?.course?.lang ? course?.course?.lang : "English"}
                    </div>
                  </div>
                </div>
                <div className='Cart_Part1_sec1_body_course_part2'>
                  <div className='Cart_Part1_sec1_body_course_part2_price'>{Math.round(course?.course?.price / 100) * 100} EGP</div>
                  <img  onClick={() => handleDelete(course?.course?.id, course?.course?.enterprise_id)} src={DeleteIcon} alt='DeleteIcon'/>
                  {removing === course?.course?.id && (
            <div className="spinner-border text-primary" role="status" style={{ marginLeft: '10px' }}>
              <span className="sr-only">Loading...</span>
            </div>
            )}
                </div>
              </div>
              <img className='cart_line' src={Line} alt='Line'/>
            </div>
          )) 
        ) : 
          (
            <div className="empty-cart-message">
            <motion.div
              initial={{ opacity: 0, scale: 0.7, rotate: -10 }}  // Starts smaller, rotated, and invisible
              animate={{
                opacity: 1, 
                scale: 1, 
                rotate: 0, // Rotate back to normal
                color: "#ff6347", // Changes color to an exciting color like tomato red
                transition: { 
                  duration: 1, 
                  ease: "easeOut", 
                  type: "spring", 
                  stiffness: 120, // Bouncy effect when it lands
                },
              }}
              whileHover={{
                scale: 1.1, // Slightly scale up when hovered
                color: "#ff4500", // Darker red on hover for emphasis
                transition: { duration: 0.3 }
              }}
              exit={{
                opacity: 0,
                scale: 0.7,
                transition: { duration: 0.5 }
              }}
              className="message-content"
            >
              Your cart is empty. Browse our courses to add them to your cart!
            </motion.div>
          </div>
          ) }
            {/*message*/}
        </div>
      
           
        {/* Part 1 Section Two */}
        <div className='Cart_Part1_sec2'>
          <div className='Cart_Part1_sec2_price'>
            <div className='Cart_Part1_sec2_price_total'>total</div>
            <div className='Cart_Part1_sec2_price_totalNum'>{courses?.total_price} EGP</div>
          </div>
          <div className='Cart_Part1_sec2_price'>
            <div className='Cart_Part1_sec2_discount'>Discount -%</div>
            <div className='Cart_Part1_sec2_discount_totalNum'>-x EGP</div>
          </div>
          <div className='Cart_Part1_sec2_checkout' onClick={handleCheckout} disabled={loading}>
            <img src={cartckeckout} alt='cartckeckout' />  {loading ? "Processing..." : "Checkout"} <img src={ArrowCheckout} alt='ArrowCheckout'/>
          </div>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', paddingLeft:'20px'}}>
            <img src={LineCheckout} alt='LineCheckout'/>
          </div>
          <div className='Cart_Part1_sec2_discountcode'>
            <img src={DiscountIcon} alt='DiscountIcon'/>Do you have a discount code?
          </div>
          <div className='Cart_Part1_sec2_discountInput'>
            <input className='Cart_Part1_sec2_discountInputfield' placeholder='Lndexfirst10'></input>
            <button className='Cart_Part1_sec2_discount_button'>APPLY</button>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Popup */}
      {showdelete && (
        <>
        <div className='Cart_Delete'>
          <div className='Cart_Delete_Exit'>
            <div></div>
            <img onClick={() => setShowdelete(false)} src={ExitIcon} alt='ExitIcon'/>
          </div>
          <div className='Cart_Delete_Question'>Are you sure you want to delete this Course?</div>
          <div className='Cart_Delete_Answer'>The list will be deleted, along with all the courses you added to it.</div>
          <div className='Cart_Delete_Button'>
            <button onClick={() => setShowdelete(false)} className='Cart_Delete_Button_cancel'>Cancel</button>
            <button onClick={handleDelete} className='Cart_Delete_Button_Delete'>Delete</button>
          </div>
        </div>
        </>
      )}
    </div>
    
  );
};

export default CartPage;
