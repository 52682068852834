import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './Course.css';
import { useDispatch , useSelector } from 'react-redux';
import { saveCourse } from '../../store/userFeature/userSavedCourseSlice';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import SavedCompact from '../../assets/images/Course/SavedCompact.svg';
import Saved from '../../assets/images/Course/CourseSaved.png';
import purchased from '../../assets/images/review/purchasedIcon.svg'
import AddToCart from '../../assets/images/coursecard/shopping-cart-details.svg';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { URL } from '../../config';
import { addCourseToCart } from '../../store/userFeature/addCourseToCartSlice';
import { fetchUserShowCart } from '../../store/userFeature/showUserCartSlice';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import LeftArrow from '../../assets/images/leftarrow.png';
import RightArrow from '../../assets/images/rightarrow.png';
import UserNavbar from '../UserNavbar/UserNavbar';

const GetEnterpriseCourse = () => {
  const dispatch = useDispatch()  
  const [savedCourses, setSavedCourses] = useState([]); // State to track saved courses
  const token = Cookies.get('authToken'); // Retrieve the token from cookies
  const [popupMessage, setPopupMessage] = useState('');
  const [defaultMessage , setdefaultMessage]=useState('')
  const [showPopup, setShowPopup] = useState(false);
  const [courses, setCourses] = useState([]);
  const { success, adding  , message } = useSelector((state) => state.userAddToCart);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(); // Reference to the course container
  const controls = useAnimation(); // Controls for the animation

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${URL}/all-enterprise-courses`, {
          method: 'GET',
          headers: {
             token, // Passing the token in the Authorization header
          },
        });

        const data = await response.json();
        if (data.status) {
          setCourses(data.data.courses);
        } else {
          setError(data.msg || 'Failed to load courses');
        }
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [token]);


  console.log(courses)



  const handleCourseClick = (id) => {
    navigate(`/enterprise/coursedetails/${id}`);
  };

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  const handleAddToCartClick = (courseId) => {
    if (!token) {
      setPopupMessage('Please log in to save courses to your cart.');
      setShowPopup(true);
      return;
    }
    dispatch(addCourseToCart({ courseId, isEnterprise: true }))
    .unwrap()
    .then((res) => {
      setPopupMessage(res.msg || "Course added successfully!");
      setShowPopup(true);
      // تحديث السلة بعد 500ms، ثم إغلاق البوب أب بعد 2 ثانية
      setTimeout(() => {
        dispatch(fetchUserShowCart());
        setShowPopup(false);
      }, 2000);
    })
    .catch((err) => {
      setPopupMessage(err.message || "Failed to add course.");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000); // إغلاق بعد ثانيتين
    });
  };

  const handleSaveCourseClick = async (courseId) => {
    if (!token) {
      setPopupMessage('Please log in to save courses.');
      setShowPopup(true);
      return;
    }

    // Optimistically update the UI
    setSavedCourses((prev) => [...prev, courseId]);


    try {
      // Dispatch the save course action
      const resultAction = await dispatch(saveCourse({ courseId, is_enterprise: true }));
  
      if (saveCourse.fulfilled.match(resultAction)) {
        setPopupMessage('Course saved successfully!');
      } else {
        // Revert optimistic update if save fails
        setSavedCourses((prev) => prev.filter((id) => id !== courseId));
        setPopupMessage(resultAction.payload?.message || 'Failed to save the course.');
      }
    } catch (error) {
      setPopupMessage('An error occurred while saving the course.');
      setSavedCourses((prev) => prev.filter((id) => id !== courseId));
    } finally {
      setShowPopup(true);
    }
  };
  

  const scrollLeft = () => {
    controls.start({ x: 0, transition: { duration: 0.8, type: 'spring' } });
    scrollContainerRef.current.scrollBy({ left: 1000, behavior: 'smooth' });
  };

  const scrollRight = () => {
    controls.start({ x: -500, transition: { duration: 0.8, type: 'spring' } });
    scrollContainerRef.current.scrollBy({ left: 50, behavior: 'smooth' });
  };

  if (loading) return <Loading />;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
  
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%' }}>
           
        <div className="Home_Sec3_Part2">
          <img src={LeftArrow} alt="ArrowLeft" onClick={scrollLeft} />
          <div>Enterprise Courses</div>
          <img src={RightArrow} alt="ArrowRight" onClick={scrollRight} />
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        className="EnterpriseCourse"
        style={{ display: 'flex', gap: '16px', overflowX: 'auto', scrollBehavior: 'smooth', paddingTop: '5px', paddingBottom: '50px', overflow: 'hidden' }}
      >
        {courses.map((course) => (
          <motion.div
            animate={controls}
            className="Course_Container"
            key={course.id}
            onClick={() => handleCourseClick(course.id)}
            whileHover={{ scale: 1 }}
          >
            <div>
              <div className="Course_Sec1">
                <img
                  className="Course_Sec1_image"
                  src={course.image}
                  alt={course.title}
                  style={{ height: '35px' }}
                />
              </div>

              <div className="Course_Sec2">Start {course?.times[0].date}</div>
              <div className="Course_Sec3">{course.title}</div>
              <div className="Course_Sec4">
                <img src={Clock} alt="Clock" /> {course.course_hours} &nbsp; (
                {course.session_count} sessions)
              </div>
              <div className="Course_Sec5">
                <img src={Chair} alt="Chair" />
                {course.seats_count} seats left
                <img src={Line} alt="Line" />
                <img src={Start} alt="Start" />
                {course.rate || '95%'} (234)
              </div>
              <div
                style={{ paddingTop: '8px', color: 'blue' }}
                className="Course_Sec6"
              >
                {course.category.title}
              </div>
              <div className="Course_Sec6" style={{display:'flex', justifyContent:'flex-start', alignItems:'center', paddingTop:'10px'}}>
                <img 
                 src={course.instructor?.profile_image}
                 alt='course.instructor?.name'
                 style={{width:'50px', height:'50px',borderRadius:'50%'}}
                 />
                {course.instructor?.name || 'N/A'}
                </div>
            </div>
            <div className="Course_Sec7_Home_guest">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveCourseClick(course.id);
                }}
              >
                {savedCourses.includes(course.id) || course.is_saved === 1 ? (
                 <img src={SavedCompact} alt="SavedCompact" />
                 ) : (
                 <img src={Saved} alt="Save" />
                )}

              </div>

              {course?.is_buy === 1  ?  
                 <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'8px'}}>
                  <img src={purchased} alt='purchased' /> 
                  <div className='purchased'>purchased</div>
                </div>
                :
                <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddToCartClick(course.id);
                }}
              >
                <img src={AddToCart} alt="addtocart_icon" />
               
              </div>
               
               }


        
             
            </div>
               
          </motion.div>
        ))}
              
              {showPopup && (
        <>
          <div className="backdrop" onClick={() => setShowPopup(false)}></div>
          <div className="popup">
            <p className='reound-popup'>{popupMessage}</p>
           
          </div>
        </>
      )}
                
        {/*showPopup && (
          <div className="popup">
            <div className="popup-content">
              <div>
                <span className="close" onClick={closePopup}>
                  &times;
                </span>
              </div>
              <div>
                <p className="Popup_Message">{popupMessage}</p>
              </div>

              {!token ? (
                <div className="popup_button_container">
                  <Link className="link" to="/Login">
                    <div>
                      <button className="popup_button_Login">Login</button>
                    </div>
                  </Link>
                  <Link className="link" to="/Signup">
                    <div>
                      <button className="popup_button_Signup">Sign Up</button>
                    </div>
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )*/}

     
       
      </div>
          {/* 
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'50%'}}>
      {success && <div className="success-message" style={{padding:'0px',margin:'0px',marginTop:'-200px',height:'50px',width:'100%'}}> {message && <div>{message}</div>} </div>}
      </div>
          */}
    </>
  );
};

export default GetEnterpriseCourse;
