import React, { useState, useEffect } from 'react';
import './InstructorNav.css';
import LndexLogo from '../../assets/images/lndexlogo_svg.svg';
import NotificationIcon from '../../assets/images/Notfication.svg';
import WorldLanguage from '../../assets/images/worldLanguage.svg';
// Redux-Toolkit  =>>>>> 
import { useDispatch, useSelector } from 'react-redux';
import { instructorLogout } from '../../store/features/instructorLoginSlice';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const InstructorNav = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.instructorLogin); // Access login state
  const navigate = useNavigate();

  // State for notification dropdown visibility
  const [showNotifications, setShowNotifications] = useState(false);

  // State for notifications data
  const [notifications, setNotifications] = useState([]);

  // Fetch notifications from API every 5 seconds (polling)
  useEffect(() => {
    const fetchNotifications = async () => {
      const token = Cookies.get('Instructor_Token');
      try {
        const response = await fetch('https://dash.lndex.io/api/instructor/notifications', {
          headers: {
            token: token, // Pass token in the header
          },
        });
        const data = await response.json();
        if (data.status && data.data.notifications) {
          setNotifications(data.data.notifications); // Update notifications
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    // Fetch notifications immediately on mount
    fetchNotifications();

    // Set up polling (fetch every 5 seconds)
    const interval = setInterval(fetchNotifications, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

// Function to mark a notification as read
const markNotificationAsRead = async (id) => {
  const token = Cookies.get('Instructor_Token');
  try {
    const response = await fetch('https://dash.lndex.io/api/instructor/read-notification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Add content type
        token: token, // Pass token in the header
      },
      body: JSON.stringify({ notification_id: id }), // Send notification_id in the body
    });
    const data = await response.json();
    if (data.status) {
      // Update the notification's is_read status in the local state
      setNotifications((prev) =>
        prev.map((note) =>
          note.id === id ? { ...note, is_read: 1 } : note
        )
      );
    }
  } catch (error) {
    console.error('Error marking notification as read:', error);
  }
};

  const handleLogout = () => {
    dispatch(instructorLogout()); // Trigger logout
    navigate('/login');
  };

  // Toggle notification dropdown
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div className='InstructorNav_Container'>
      {/* The LeftSide Logo */}
      <div className='InstructorNav_Logo'>
        <Link to='/Instructor-Home' className='link'>
          <img src={LndexLogo} alt='logo' />
        </Link>
      </div>

      {/* The Right Side */}
      <div className='InstructorNav_RightSide'>
        {/* Notification Icon and Dropdown */}
        <div className='notification-wrapper'>
          <img
            src={NotificationIcon}
            alt='notificationIcon'
            onClick={toggleNotifications}
            style={{ cursor: 'pointer' }}
          />
          {/* Notification Dropdown */}
          {showNotifications && (
            <div className='notification-dropdown'>
              {notifications.length > 0 ? (
                notifications.map((note) => (
                  <div
                  key={note.id}
                  className={`notification-item ${note.is_read ? 'read' : 'unread'}`}
                  onClick={() => !note.is_read && markNotificationAsRead(note.id)} // Mark as read on click
                >
                  <div className='notification-body'>{note.body}</div>
                  <div className='notification-date'>{note.date}</div>
                </div>
                ))
              ) : (
                <div className='notification-item'>No new notifications.</div>
              )}
            </div>
          )}
        </div>

        {/* Language Button */}
        <div>
          <button className='InstructorNav_LanguageButton'>
            <img src={WorldLanguage} alt='langIcon' /> العربيه
          </button>
        </div>

        {/* Logout Button */}
        <div>
          <button onClick={handleLogout} disabled={loading} className='InstructorNav_LogoutButton'>
            {loading ? (
              <div className='spinner-border spinner-border-sm text-light' role='status'>
                <span className='sr-only' style={{ color: '#000' }}></span>
              </div>
            ) : (
              'Logout'
            )}
          </button>
        </div>
      </div>

      {/* Display error if any */}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default InstructorNav;