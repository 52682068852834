import React, { useState } from 'react';
import axios from 'axios';
import SignupImage from '../../assets/images/signup.png';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import Cookies from 'js-cookie';
import '../LoginPage/LoginPage.css';
import './SignupPage.css'
import { useNavigate } from 'react-router-dom';
import { URL } from '../../config';
import EnterpriseLoginImage from '../../assets/images/EnterpriseLogin.svg'
import instructorLoginImage from '../../assets/images/instructorLogin.svg'
import studentLoginImage from '../../assets/images/studentLogin.svg'
import { Link } from 'react-router-dom';
import CityDropdown from './CityID';
import EnterpriseForm from './EnterpriseForm';



const SignupPage = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState('');
    const [active, setActive] = useState('Student'); // default is 'instructor'
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mobile_id, setmobile_id] = useState('0');
    const [loading, setLoading] = useState(false); // State to manage loading spinner
    const [error, setError] = useState(null);
    const [phone, setPhone] = useState('');
      // Select City By Id 
      const [selectedCity, setSelectedCity] = useState('');
    
       const handleCityChange = (cityId) => {
         setSelectedCity(cityId);
        };
   
    const handleToggle = (role) => {
        setActive(role); // change the active role
        setRole(role)
        console.log(role)
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);  // Set loading state to true
        // Determine which URL to use based on role
        let apiUrl = role === 'Instructor' 
            ? `${URL}/instructor/register`
            : `${URL}/register`;
    
        // Prepare the data payload
        const data = {
            first_name: first_name,
            last_name: last_name,
            phone : phone ,
            email: email,
            password: password,
            mobile_id: mobile_id
        };
    
        // Include phone and address if role is Instructor
        if (role === 'Instructor') {
          
            data.city_id = selectedCity;
        }
       console.log('instructordata',data)
        try {
            const response = await axios.post(apiUrl, data);
    
            if (response.status === 200 || response.status === 201) {
                 console.log('print response status',response.status)
                 console.log(response)
                if (role === 'Instructor') {
                    const instToken = response.data?.data?.instructor?.token;
                    Cookies.set('authinst_Token', instToken, { expires: 1 }); // Cookie will expire in 7 days
                    const userData = {
                        first_name: response.data.data.instructor.first_name,
                        last_name: response.data.data.instructor.last_name,
                        email: response.data.data.instructor.email,
                        phone: response.data.data.instructor.phone,
                        city_id : response.data.data.instructor.city_id

                    };
        
                    localStorage.setItem("user_data", JSON.stringify(userData));
                }
                else {
                const Token = response.data?.data?.user?.token;
                Cookies.set('authToken', Token, { expires: 7 }); // Cookie will expire in 7 days
                const userData = {
                    first_name: response.data.data.user.first_name,
                    last_name: response.data.data.user.last_name,
                    email: response.data.data.user.email,
                    phone : response.data.data.user.phone
                };
    
                localStorage.setItem("user_data", JSON.stringify(userData));
                }
                // Role-based redirect logic
                if (role === 'Instructor') {
                    navigate('/instructor-VerficationEmail'); // Redirect instructors
                } else {
                    navigate('/verfiy-email'); // Redirect students
                }
            } else if (response.status === 422 || response.status === 401) {
                setError(response.data.message || "Signup failed. Please check your input.");
            }
        } catch (err) {
            console.error('Signup failed:', err);
            setError('Signup failed. Please try again later.');
        }
     finally {
        setLoading(false);  // Set loading state to false after completion
      }
    };

    return (
        <div className='SignUP_page_container' style={{marginTop:'25px'}}>
          
            <div className='Registrition_Body'>
                {/* Start signup body */}


                <div className="Login_page_hidden">
         {active ? (
                <img
                  src={
                  active === "Instructor"
                  ? instructorLoginImage
                  : active === "Student"
                  ? studentLoginImage
                  : EnterpriseLoginImage
                  }
                  alt="SignupImage"
                  loading="lazy"
                 />
                ) : (
               <div className="image-placeholder">Loading...</div>
              )}
</div>

                <div className='Signup_body'>


                    <div className='Signup_page_Image'>
                        <img src={SignupImage} alt='SignupImage' />
                    </div>
                    <div className='Signup_body_Form'>
                        <div className='Signup_body_Form_Sec1'>
                            <div className='Loggo_Container'>
                              <img src={Logo} alt='Logo'/>
                            </div>
                          
                            <div className="Instructor_Student_Container_login">
                            <h6
                                   className={active === 'Student' ? 'active' : ''}
                                   onClick={() => handleToggle('Student')}
                                 >
                                  Student
                                 </h6>
                                  | 

                                 <h6
                                   className={active === 'Instructor' ? 'active' : ''}
                                   onClick={() => handleToggle('Instructor')}
                                 >
                                 Instructor
                                 </h6>
                                   |
                                   <h6
                                   className={active === 'Enterprise' ? 'active' : ''}
                                   onClick={() => handleToggle('Enterprise')}
                                   >
                                   Enterprise
                                   </h6>
                               
                       </div>
                        </div>
                        {/* 
                             <div className='Signup_body_Form_Sec2'>
                            Sign Up 
                             </div>
                        */}
                     
                        <div className='Signup_body_Form_Sec3'>
                            <hr />
                        </div>
                        {/* Start input Field */}
                        {role === "Enterprise" ? <EnterpriseForm /> : 
                        <div className='Signup_body_input_container'>
                            {/* First and Last Name */}
                            <div className='Signup_body_Form_FirstAndLast'>
                                <div>
                                    <div>First Name</div>
                                    <div className='Signup_body_input'>
                                        <input
                                            className='Sign_input'
                                            type="text"
                                            placeholder='First Name'
                                            value={first_name}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div> Last Name</div>
                                    <div className='Signup_body_input'>
                                        <input
                                            className='Sign_input'
                                            type="text"
                                            placeholder='Last Name'
                                            value={last_name}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='Signup_body_Form_Sec4'>
                                        <div>Phone</div>
                                        <div className='Signup_body_input'>
                                            <input
                                                className='Sign_input'
                                                type="text"
                                                placeholder='Your phone number'
                                                value={phone}
                                                onChange={(e)=>setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                            {/* Email */}
                            <div className='Signup_body_Form_Sec4'>
                                <div>Email</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder='Example@example.com'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* Password */}
                            <div className='Signup_body_Form_Sec4'>
                                <div>Password</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="password"
                                        placeholder='Password'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* Role Selection 
                            <div className='Login_page_DropDown'>
                                <label className='ROle' htmlFor="roleSelect">Select Role:</label>
                                <select id="roleSelect" value={role} onChange={handleRoleChange}>
                                    <option value="" disabled>Select your role</option>
                                    <option value="Instructor">Instructor</option>
                                    <option value="Student">Student</option>
                                </select>
                            </div>
                               */}
                            {/* Conditionally render phone and address fields if role is Instructor */}
                            {role === 'Instructor' && (
                                <>
                             
                                        <div className='Signup_body_Form_Sec4' >
                                            <div>City</div>
                                            <div className='Signup_body_input_city'  >
                                                 <CityDropdown
                                                   selectedCity={selectedCity}
                                                   onCityChange={handleCityChange}
                                                   language="en" // or 'ar' based on preference
                                              
                                                  />
                                              </div>
                                          </div>
                                </>
                            )}

                           <div className='Registration_body_Form_Sec5' style={{marginTop:'25px'}}>
                            {/*
                                This Section About Buttton To Sign up 
                            */}
                            {/* Signup button with spinner */}
      <button onClick={handleSubmit} className='Signup_body_Form_Sec5_button' disabled={loading}>
        {loading ? (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          "Sign Up"
        )}
      </button>

     
                            <div>Already have an account? <Link to='/login' className='link'> <span style={{color:'#3F52FC'}}>Log In</span></Link></div>
                          
                        </div>

                        <div className='Error_message'>{error}</div>
                        </div>


                         }  {/* End About This Role if Enterprise Show a Form Otherwise Show form Belong To User and Instructor  */}

                        {/* End input Field Container */}
                    
                      

                       

                        <div className='Signup_body_Form_Sec3'>
                            <hr />
                        </div>
                     {/*   <div className='Signup_body_Form_Sec6_footer'>
                            By clicking on sign up, you agree to Lndex <span>Terms and conditions</span> & <span>Privacy policy</span>, and agree to receive news and promotions.
                        </div> */}
                    </div> {/* End Form */}
                </div>
            </div>
        </div>
    );
};

export default SignupPage;
