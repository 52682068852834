import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { URL } from '../../config';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const otp_code = location.state?.otp_code; // Retrieve OTP code from the previous component

  useEffect(() => {
    if (!otp_code) {
      navigate('/Login'); // If OTP code is not passed, redirect to login
    }
  }, [otp_code, navigate]);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`${URL}/change-password`, {
        otp_code: otp_code,
        new_password: password
      });
      console.log('Password changed successfully:', response.data);
      navigate('/Login'); // Redirect to login page after password change
    } catch (err) {
      setError('Error changing password.');
      console.error('Error:', err);
    }
  };

  const handleCancel = () => {
    navigate('/Login'); // Redirect to login page if user clicks cancel
  };

  return (
    <div>
      <div className='Login_page_container'>
        {/* Start signup body */}
        <div className='Signup_body'>
          <div className='Signup_body_Form'>
            <div className='Signup_body_Form_Sec1'>
              <img src={Logo} alt='Logo' />
            </div>
            <div className='Signup_body_Form_Sec2'>
              Change Password
            </div>

            {/* Start input fields */}
            <div className='Signup_body_input_container'>
              {/* New Password */}
              <div className='Signup_body_Form_Sec4'>
                <div style={{ display: 'flex', justifyContent: 'center' , margin:'5px'}} className='Signup_body_input'>
                  <input
                    style={{ padding: '10px' }}
                    className='Sign_input'
                    type='password'
                    placeholder='Enter New Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              {/* Confirm Password */}
              <div className='Signup_body_Form_Sec4'>
                <div style={{ display: 'flex', justifyContent: 'center', margin:'5px' }} className='Signup_body_input'>
                  <input
                    style={{ padding: '10px' }}
                    className='Sign_input'
                    type='password'
                    placeholder='Confirm Password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* End input fields */}

            {error && <div className='Error_message'>{error}</div>}
            <div className='Registration_body_Form_Sec5'>
              <button className='Signup_body_Form_Sec5_button' onClick={handleSubmit}>
                Continue
              </button>
              <button style={{ background: 'gray' }} className='Signup_body_Form_Sec5_button' onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
