import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { URL } from '../../config';
import Cookies from "js-cookie";

// Define the async thunk for fetching data
export const fetchStoreBranches = createAsyncThunk(
  'storeBranches/fetchStoreBranches',
  async (formData, { rejectWithValue }) => {
    const token = Cookies.get("entoken");
    try {
      const response = await axios.post(`${URL}/enterprise/branches/store`, formData, {
        headers: {
          token
        }
      });
      return response.data;
    } catch (err) {
      // Here we can handle errors
      return rejectWithValue(err.response.data);
    }
  }
);

const storeBranchesSlice = createSlice({
  name: 'storeBranches',
  initialState: {
    branches: [],
    status: 'idle',
    error: null
  },
  reducers: {
    // You can add other synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreBranches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStoreBranches.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.branches = action.payload;
      })
      .addCase(fetchStoreBranches.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default storeBranchesSlice.reducer;