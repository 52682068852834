import React, { useState , useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEnterpriseCourseById } from '../../store/enterpriseFeature/getenterprisecoursebyidSlice';
import { updateEnterpriseCourse } from '../../store/enterpriseFeature/updateEnterpriseCourseSlice';
import CourseLevelSelector from '../../pages/InstructorAsUser/InstructorDashboard/CourseLevelSelector';
import CategoryDropdown from '../../pages/InstructorAsUser/InstructorDashboard/Category';
import CityDropdown from '../../pages/InstructorAsUser/InstructorDashboard/City';
import { motion } from 'framer-motion';
import { useParams } from "react-router-dom";
const UpdateEnterpriseCourse = () => {
  const { id } = useParams();
  const { course, error, loading: courseLoading } = useSelector((state) => state.getenterprisecoursebyid);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  console.log('test get course',course,id); // Check if id is undefined
  // Form state
  
  const [formData, setFormData] = useState({
    title: '',
    courseHours: '',
    sessionCount: '',
    seatsCount: '',
    description: '',
    has_certificate: '',
    category_id: '',
    level_id: '',
    type: '1',
    city_id: '',
    price: '',
  });
   
  const [previewImage, setPreviewImage] = useState(course?.image || '');
  useEffect(() => {
    if (id) {
      dispatch(fetchEnterpriseCourseById(id));
    }
  }, [dispatch, id]);


  useEffect(() => {
    if (course) {
      setFormData({
        title: course.title || '',
        courseHours: course.course_hours || '',
        sessionCount: course.session_count || '',
        seatsCount: course.seats_count || '',
        description: course.description || '',
        has_certificate: course.has_certificate || '',
        category_id: course.category?.id || '',
        level_id: course.level?.id || '',
        type: course.type || '1',
        city_id: course.city?.id || '',
        price: course.price || '',
      });
      setPreviewImage(course.image || '');
      setLocation({
        lat: String(course.lat) || '',
        lng: String(course.lng) || '',
      });
    }
  }, [course]);



  const [location, setLocation] = useState({
    lat: String(course?.lat) || '',
    lng: String(course?.lng) || '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Redux selector for error/success (optional)
  const { loading } = useSelector((state) => state.updateEnterpriseCourse);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCategoryChange = (categoryId) => {
    setFormData((prevData) => ({
      ...prevData,
      category_id: categoryId,
    }));
  };

  const handleLevelChange = (levelId) => {
    setFormData((prevData) => ({
      ...prevData,
      level_id: levelId,
    }));
  };

  const handleCityChange = (cityId) => {
    setFormData((prevData) => ({
      ...prevData,
      city_id: cityId,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    formDataToSend.append('lat', location.lat);
    formDataToSend.append('lng', location.lng);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    //formDataToSend.append('content', formData.content);
    formDataToSend.append('course_hours', formData.courseHours);
    formDataToSend.append('session_count', formData.sessionCount);
    formDataToSend.append('seats_count', formData.seatsCount);
    formDataToSend.append('has_certificate', formData.has_certificate);
    const courseId = course?.id;

    dispatch(updateEnterpriseCourse({ courseId, updateData: formDataToSend }))
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        navigate('/enterprise/home');
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.error('Failed to update course:', err);
      });
  };

  return (
    <div className="update-course-container">
    <div style={{display:'flex',alignItems:'center' , width:'100%', justifyContent:'center',paddingBottom:'5vw'}}>    <h3>Course {course?.title}</h3>  <div></div>  </div>
    <form onSubmit={handleSubmit}>
      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Course Title</label>
        <input
          type="text"
          name="title"
          className="update-course-input"
          value={formData.title}
          onChange={handleInputChange}
        />
      </div>

      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Course Price</label>
        <input
          type="text"
          name="price"
          className="update-course-input"
          value={formData.price}
          onChange={handleInputChange}
        />
      </div>



      {/* 
      <div>
        <label>Start Date</label>
        <input
          className="update-course-input"
          type="date"
          name="startDate"
          value={formData.created_at}
          onChange={handleInputChange}
        />
      </div>
*/}

     {/* Start DropDown List 

     <div style={{ display: 'flex', gap:'5vw' , width:'60vw'}}>
<div>
  <div className="InstructorCreateCourse_form_Section_title">Course Category</div>
  <CategoryDropdown
    selectedCategory={formData.category_id}
    onCategoryChange={handleCategoryChange}
  />
</div>

<div>
  <div className="InstructorCreateCourse_form_Section_title">Course Level</div>
  <CourseLevelSelector
    selectedLevel={formData.level_id}
    onLevelChange={handleLevelChange}
  />
</div>
</div>
 */}

           {/* Another DropDown */}

           <div style={{ display: 'flex', gap:'5vw',width:'50vw' }}>  
<div>
  <div className="InstructorCreateCourse_form_Section_title">Type Of Course</div>
  <select
    name="type"
    value={formData.type}
    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
    className="city-select"
    style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
  >
    <option value="1">Offline</option>
    <option value="0">Online</option>
  </select>
</div>

<div>
  <div className="InstructorCreateCourse_form_Section_title">City</div>
  <CityDropdown
    selectedCity={formData.city_id}
    onCityChange={handleCityChange}
    language="en" // or 'ar' for Arabic
  />
</div>

</div>


      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Course Hours</label>
        <input
          className="update-course-input"
          type="number"
          name="courseHours"
          value={formData.courseHours}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Session Count</label>
        <input
          className="update-course-input"
          type="number"
          name="sessionCount"
          value={formData.sessionCount}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Remaining Seats</label>
        <input
          className="update-course-input"
          type="number"
          name="seatsCount"
          value={formData.seatsCount}
          onChange={handleInputChange}
        />
      </div>

      <div className="InstructorCreateCourse_form_Section">
               <label className='InstructorCreateCourse_form_Section_title'>Has Certificate</label>
           <select
               name="has_certicate"
               value={formData.has_certicate}
               onChange={handleInputChange}
               className="InstructorCreateCourse_form_Section_input"
           >
                     <option value="1">Yes</option>
                     <option value="0">No</option>
                </select>
          </div>


      <div>

    {/* 
        <label> Course Rate </label>
        <input
          className="update-course-input"
          type="text"
          name="rate"
          value={formData.rate}
          onChange={handleInputChange}
        />

        */}

          </div>
      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Course Description</label>
        <textarea
          className="update-course-input"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />
      </div>
      {/* 
      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Course Content</label>
        <textarea
          className="update-course-input"
          name="content"
          value={formData.content}
          onChange={handleInputChange}
        />
      </div>
      */}
      <div>
        <label className='InstructorCreateCourse_form_Section_title'>Course Image</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>
      {previewImage && (
        <div style={{display:'flex', justifyContent:'center' , alignItems:'center'}}>
          <img src={previewImage} style={{width:'350px'}} alt="Course Preview" />
        </div>
      )}

      {/* Google Map for Course Location 
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
          zoom={12}
          onClick={handleMapClick}
        >
          <Marker position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }} />
        </GoogleMap>
      ) : (
        <p>Loading map...</p>
      )}
        */}
     <div style={{display:'flex', justifyContent:'flex-end', padding:'20px'}}>
     <motion.button
      disabled={isSubmitting || loading}
      type="submit"
      style={{display:'flex', justifyContent:'center'}}
      className="update-course-submit-button"
      whileHover={!isSubmitting && !loading ? { scale: 1.05 } : {}}
      whileTap={!isSubmitting && !loading ? { scale: 0.95 } : {}}
    >
      {isSubmitting || loading ? (
        <motion.div
          className="spinner"
          animate={{ rotate: 360 }}
          transition={{
            repeat: Infinity,
            ease: "linear",
            duration: 1,
          }}
        />
      ) : (
        'Save Changes'
      )}
    </motion.button>
     </div>
     </form>
 
    
  </div>
  );
};

export default UpdateEnterpriseCourse;
