import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './AuthSlice';
import instructorLoginReducer from './features/instructorLoginSlice';
import instructorProfileReducer from './features/instructorGetProfileSlice';
import instructorArticleIdReducer from './features/instructorArticleIdSlice';
import getArticleByIdReducer from './features/getArticleByIdSlice';
import updateArticleByIdReducer from './features/instructorUpdateArticleSlice';
import updateProfileImageReducer from './features/instructorProfilePictureSlice';
import instructorStoreCourseReducer from './features/instructorStoreCourseSlice';
import updateInstructorProfileReducer from './features/InstructorUpdateProfileSlice';
import instructorsReducer from './features/Getallinstructor'; // Import the new slice
import instructorCourseReducer  from './features/instructorCoursesSlice';
import instructorUpdateCourseReducer from './features/instructorUpdateCourseSlice';
import GetCourseReducer from './features/GetCourses';
import instructorCoursesByIdReducer  from './userFeature/showCoursesByInstructorIdSlice'
import instructorArticlesByIdReducer from './userFeature/showArticlesByInstructorSlise'
// Here The User Reducer 
import userAddToCartReducer from './userFeature/addCourseToCartSlice'
import userShowCartReducer from './userFeature/showUserCartSlice'; // استيراد الـ slice
import userFollowInstructorReducer from './userFeature/userFollowInstructorSlice' // import user follow instructor Slice 
import userSavedCourseReducer from './userFeature/userSavedCourseSlice' 
import userFetchSavedCourseReducer from './userFeature/userFetchSavedCourseSlice'
import userDeleteFromCartReducer from './userFeature/userDeleteFromCartSlice'
import userDeleteFromSaveReducer from './userFeature/userDeleteFromSaveSlice'
import userotpVerificationReducer  from './userFeature/otpVerificationSlice.js' 
import userFetchAllInstructorsReducer from './userFeature/userFetchAllInstructors.js'
import userfetchallCoursesReducer  from './userFeature/GetUserCourseSlice.js';
import myCoursesReducer from './userFeature/MyCoursesSlice.js'
// End The User  Reducer 
import userUpdateProfileReducer  from './userFeature/userUpdateImageProfileSlice';
import userDataReducer  from './userFeature/userDataSlice';
import CategoryReducer from './userFeature/userShowCategorySlice'
import updatePasswordReducer  from './features/InstructorUpdatePasswordSlice'
import CourseArchiveReducer from './features/CourseArchiveSlice'
import CourseUnArchiveReducer from './features/CourseUnArchiveSlice'
import courseDeleteReducer from './features/CourseDeleteSlice'; // Import your new slice
import archiveArticleReducer from './features/ArticleArchiveSlice';
import unarchiveArticleReducer from './features/ArticleUnArchiveSlice'
import instructorForgetPasswordReducer  from './features/instructorForgetPasswordSlice';
import instructorCheckForgetCodeReducer from './features/instructorCheckForgetCodeSlice';
import instructorOtpChangePasswordReducer from './features/instructorOtpChangePasswordSlice';
// quest or user show the course 
import GuestgetCourseByIdReducer from './userFeature/GestgetCourseByIdSlice'
import categoryReducer  from './categorySlice.js'
// Enterprise Reducer 
import registerEnterpriseReducer  from './enterpriseFeature/registerEnterpriseSlice';
import validateCodeEnterpriseReducer from './enterpriseFeature/validatecodeEnterpriseSlice'; // استيراد الـ reducer الخاص بالـ validateCodeEnterprise
import loginEnterpriseReducer  from './enterpriseFeature/loginEnterpriseSlice';
import updatepasswordEnterpriseReducer from './enterpriseFeature/updatepasswordEnterpriseSlice';
import deactivateUserReducer  from './enterpriseFeature/deactiveuserSlice';
import profileEnterpriseReducer from './enterpriseFeature/profileEnterpriseSlice';
import logoutEnterpriseReducer  from './enterpriseFeature/logoutEnterpriseSlice.js';
import updateEnterpriseImageReducer from './enterpriseFeature/profilepictureEnterprise.js';
import enterpriseInstructorsReducer from './enterpriseFeature/instructorEnterpriseSlice.js';
import registerEnterpriseCourseReducer  from './enterpriseFeature/storecourseEnterpriseSlice.js';
import updateEnterpriseProfileReducer from "./enterpriseFeature/updateEnterpriseProfileSlice";
import enterpriseCoursesReducer from './enterpriseFeature/enterpriseCoursesSlice.js'
import archiveEnterpriseCourseReducer  from './enterpriseFeature/archiveEnterpriseCourseSlice.js'
import unarchiveEnterpriseCourseReducer from './enterpriseFeature/unarchiveEnterpriseCourseSlice';
import updateEnterpriseCourseReducer  from './enterpriseFeature/updateEnterpriseCourseSlice.js'
import destroyEnterpriseCourseReducer  from './enterpriseFeature/destroyEnterpriseCourseSlice.js'
import enterpriseForgetPasswordReducer  from './enterpriseFeature/enterpriseForgetPasswordSlice.js'
import checkForgetCodeReducer from './enterpriseFeature/checkForgetCodeSlice.js'
import enterpriseChangePasswordReducer  from './enterpriseFeature/enterpriseChangePasswordSlice.js'
import { userFetchAllInstructors } from './userFeature/userFetchAllInstructors.js';
import  storeInstructorEnterpriseReducer  from './enterpriseFeature/storeInstructorEnterpriseSlice.js';
import instructorEnterpriseByidReducer from './enterpriseFeature/instructorEnterpriseByidSlice'; // Correct import path
import updateInstructorEnterpriseReducer  from './enterpriseFeature/updateinstructorEnterpriseSlice.js';
import getenterprisecoursebyidReducer from './enterpriseFeature/getenterprisecoursebyidSlice.js'
// Branches 
import StorebrancheReducer from './branches/StorebrancheSlise.js'
import displayBranchesReducer from './branches/getBranchesSlice.js' 
import deleteBranchReducer  from './branches/deletebranchSlice.js'
import getbranchbyidReducer from './branches/getbranchbyidSlice.js'
import updateBranchReducer  from './branches/updatebranchSlice.js';
// payment 
import paymentReducer from './payment/PaymentSlice.js'
// Review 
import ReviewReducer from './Review/ReviewSlice.js'
// Courses 
import GetcourseIdReducer from './UserCoursesID/CourseIDSlice.js'
import enterpriseCourseIDReducer  from './UserCoursesID/EnterpriseCourseIDSlice.js'

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    instructorLogin: instructorLoginReducer,
    instructorProfile: instructorProfileReducer,   
    instructorArticle: instructorArticleIdReducer,
    getArticleById: getArticleByIdReducer,
    updateArticleById: updateArticleByIdReducer,
    updateProfileImage: updateProfileImageReducer,
    instructorStoreCourse: instructorStoreCourseReducer,
    updateInstructorProfile: updateInstructorProfileReducer,
    instructors: instructorsReducer ,// Add the instructors slice here
    instructorCourses  : instructorCourseReducer , 
    instructorUpdateCourse :  instructorUpdateCourseReducer,
    GetCourse : GetCourseReducer ,
    instructorCoursesById : instructorCoursesByIdReducer , 
    instructorArticleById : instructorArticlesByIdReducer ,
    category :categoryReducer , 
     // this about user reducer add to the store 
    userUpdateProfile    : userUpdateProfileReducer ,
    userData : userDataReducer ,
    userAddToCart :  userAddToCartReducer ,
    userShowCart: userShowCartReducer,
    userFollowInstructor : userFollowInstructorReducer , 
    userSavedCourse : userSavedCourseReducer ,
    userFetchSavedCourse: userFetchSavedCourseReducer ,
    userDeleteFromCart : userDeleteFromCartReducer,
    userDeleteFromSave : userDeleteFromSaveReducer ,
    userotpVerification: userotpVerificationReducer, // Add the OTP verification reducer
    myCourses:myCoursesReducer ,
    allCourses : userfetchallCoursesReducer ,
    categories  : CategoryReducer ,
    GuestGetCourse : GuestgetCourseByIdReducer ,

    userGetAllInstructors: userFetchAllInstructorsReducer, // Add the userFetchAllInstructors reducer
    // instructor update Api 
    updatePasswordinstructor: updatePasswordReducer,
    courseArchive : CourseArchiveReducer ,
    courseUnArchive : CourseUnArchiveReducer,
    courseDelete : courseDeleteReducer ,
    archiveArticle  :  archiveArticleReducer,
    unarchiveArticle : unarchiveArticleReducer , 
    instructorForgetPassword :  instructorForgetPasswordReducer ,
    instructorCheckForgetCode: instructorCheckForgetCodeReducer, // Add the new reducer
    instructorOtpChangePassword: instructorOtpChangePasswordReducer, // Add the reducer here
    // EnterPrise 
    registerenterprise: registerEnterpriseReducer, // Add the reducer under the "enterprise" key
    enterpriseValidateCode: validateCodeEnterpriseReducer, // إضافة الـ reducer في الـ store
    loginenterprise : loginEnterpriseReducer,
    updatepasswordenterprise: updatepasswordEnterpriseReducer,
    deactivateUser : deactivateUserReducer ,
    enterpriseProfile: profileEnterpriseReducer,
    logoutEnterprise: logoutEnterpriseReducer ,
    updateProfileImage: updateEnterpriseImageReducer,  // Register the reducer
    enterpriseInstructors: enterpriseInstructorsReducer,
    enterpriseCourse: registerEnterpriseCourseReducer, // Add the reducer here
    updateEnterpriseProfile: updateEnterpriseProfileReducer,
    enterpriseCourses : enterpriseCoursesReducer ,
    archiveEnterpriseCourse: archiveEnterpriseCourseReducer,
    unarchiveEnterpriseCourse: unarchiveEnterpriseCourseReducer,
    updateEnterpriseCourse: updateEnterpriseCourseReducer,
    destroyEnterpriseCourse: destroyEnterpriseCourseReducer,
    enterpriseForgetPassword: enterpriseForgetPasswordReducer,
    checkForgetCode: checkForgetCodeReducer,
    enterpriseChangePassword: enterpriseChangePasswordReducer, // Matches the selector key
    storeInstructorEnterprise : storeInstructorEnterpriseReducer ,
    instructorEnterpriseByid : instructorEnterpriseByidReducer ,
    updateInstructorEnterprise : updateInstructorEnterpriseReducer ,
    storeBranches : StorebrancheReducer ,
    displayBranches: displayBranchesReducer,
    deleteBranch: deleteBranchReducer,
    getBranchById: getbranchbyidReducer,
    getenterprisecoursebyid :getenterprisecoursebyidReducer,
    updateBranch : updateBranchReducer ,
    // payment
    payment: paymentReducer,
    // Review
    Review : ReviewReducer ,
    // Course By ID  
    GetcourseId:GetcourseIdReducer,
    enterpriseCourseID: enterpriseCourseIDReducer, // إضافة سلايس المؤسسة
  },
});

export default store;
