import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { URL } from "../../config";

// Async Thunk to Fetch Enterprise Course by ID
export const fetchEnterpriseCourseById = createAsyncThunk(
  "enterpriseCourse/fetchById",
  async (id, { rejectWithValue }) => {
    try {
      const token = Cookies.get("entoken"); // Get token from cookies
      const response = await axios.get(`${URL}/enterprise/courses/${id}`, {
        headers: {
         token
        },
      });
      return response.data.data.course; // Return course data
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching course");
    }
  }
);

const enterpriseCourseSlice = createSlice({
  name: "enterpriseCourse",
  initialState: {
    course: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnterpriseCourseById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEnterpriseCourseById.fulfilled, (state, action) => {
        state.loading = false;
        state.course = action.payload;
      })
      .addCase(fetchEnterpriseCourseById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default enterpriseCourseSlice.reducer;
