import React, { useState, useRef, useEffect } from 'react';
import { useParams , useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './CourseDetails.css'
import ArrowLeftGuide from '../../assets/images/ArrowLeft.svg'
import { Link } from 'react-router-dom'
//import CoursedImage from '../../assets/images/Course/coursedetils.png'
import LndexLogo from '../../assets/images/lndexlogo_svg.svg'
import coursesaved from '../../assets/images/Course/Saved.svg'
//import calenderGoogle from '../../assets/images/Course/calendar-add (1).svg'
import Description from '../../component/CourseDeatils/Description'
import Content from '../../component/CourseDeatils/Content'
import CourseLocation from '../../component/CourseDeatils/CourseLocation'
import Review from '../../component/CourseDeatils/Review'
/* start import icon for cart */
import datestart from '../../assets/images/coursecard/blank-calendar.svg'
import ShopingCart from '../../assets/images/coursecard/shopping-cart-details.svg'
//import giveGift from '../../assets/images/coursecard/give-gift.svg'
import beginner from '../../assets/images/coursecard/beginner.svg'
import Line from '../../assets/images/coursecard/LineCourseCard.svg'
import Hours from '../../assets/images/coursecard/clock.svg'
import Chairs from '../../assets/images/coursecard/sets.svg'
import Certificate from '../../assets/images/coursecard/certificate.svg'
import Arabic from '../../assets/images/coursecard/volume.svg'
import Star from '../../assets/images/coursecard/Star.svg'
import purchased from '../../assets/images/review/purchasedIcon.svg'
//import InstructorIcon from '../../assets/images/instructors/instructor1.png'
//import Course from '../../component/Course/Course'
//import LeftArrow from '../../assets/images/leftarrow.png'
//import RightArrow from '../../assets/images/rightarrow.png'
import YouTubePlayer from '../../component/YoutubePlayer/YoutubePlayer';
import Loading from '../../component/Loading/Loading';
import { URL } from '../../config';
import Footer from '../../component/Footer/Footer';
import UserNavbar from '../../component/UserNavbar/UserNavbar';
import Cookies from 'js-cookie';
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar';
import { addCourseToCart } from '../../store/userFeature/addCourseToCartSlice';
import { fetchUserShowCart } from '../../store/userFeature/showUserCartSlice';
import { motion } from 'framer-motion';

const EnterpriseCourseDetails = () => {
         const dispatch = useDispatch() 
         const navigate = useNavigate();
         const [courses, setCourses] = useState([]);
         const [loading, setLoading] = useState(true);
         const [isLoading, setIsLoading] = useState(false);
         const [error, setError] = useState(null);
         const token = Cookies.get('authToken');
         const { id } = useParams(); // استخراج المعرف من الرابط
         // Fetch the course list from Redux store
 
    useEffect(() => {
          const fetchCourses = async () => {
          try {
          const response = await fetch(`${URL}/enterprise/all-courses`);
          const data = await response.json();
          if (data.status) {
            setCourses(data.data.courses);
          } else {
            setError(data.msg || 'Failed to load courses');
          }
        } catch (err) {
          setError(err.message || 'An error occurred');
        } finally {
          setLoading(false);
        }
      };
  
      fetchCourses();
    }, []);

  console.log('Course ID:', id);
  console.log('All Courses:', courses);


   // تحويل المعرف إلى نوع رقم للتأكد من تطابقه مع نوع البيانات في القائمة
   const courseId = parseInt(id, 10);
   // البحث عن الدورة باستخدام المعرف
   const course = courses.find((course) => course.id === courseId);

  console.log('Found Course:', course); // تحقق من أن الكورس تم العثور عليه
  //console.log('video', course?.promo_video_link)
  const Spinner = () => (
    <motion.div
      style={{
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        border: '3px solid #f3f3f3',
        borderTop: '3px solid #3498db',
      }}
      animate={{ rotate: 360 }} // Rotate animation
      transition={{ duration: 1, repeat: Infinity, ease: 'linear' }} // Infinite loop
    />
  );

     {/* 
    const courseContainerRef = useRef(null);
    const scrollLeft = () => {
        if (courseContainerRef.current) {
            courseContainerRef.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scrollRight = () => {
        if (courseContainerRef.current) {
            courseContainerRef.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
      // using instructor Course Ref 
    const  CoursesByInstructor = useRef(null)
      const scroll_Instructor_Course_Left = () => {
        if (CoursesByInstructor.current) {
          CoursesByInstructor.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scroll_Instructor_Course_Right = () => {
        if (CoursesByInstructor.current) {
          CoursesByInstructor.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
    */}

    // To active Section 4 Item 
    
   const [activeSection , setActiveSection]= useState('Description')
   const renderSection = () => {
    switch (activeSection) {
      case 'Description':
        return <Description course={course}/>;
      case 'Content':
        return   <Content  course={course.content_data}/> ;
      case 'Course Location':
        return <CourseLocation course={course.instructor.enterprise}/>;
      case 'Review':
        const isEnterprise = 1;
        return <Review courseId={courseId} isEnterprise={isEnterprise} />;
      default:
        return null;
    }
  };
   function clicktest() {
    console.log('click test ')
   }
   console.log(course);
   console.log(course?.promo_video_link);

   const handleAddToCartClick = (courseId) => {
    setIsLoading(true); // Start loading
    dispatch(addCourseToCart({ courseId, isEnterprise: true }))
      .then(() => dispatch(fetchUserShowCart()))
      .then(() => navigate('/UserCart'))
      .finally(() => setIsLoading(false)); // Stop loading
  };




  return (
    <div>
        {/* Render UserNavbar if token exists, else UnauthAppbar */}
      {token ? (
        <div style={{display:'flex', justifyContent:'space-around'}}>
          <img src={LndexLogo} alt='logo'/>
          <UserNavbar />
        </div>
       
      ) : (
        <UnauthAppbar
    
        />
      )}
       {/* start Course details Contianer */}
          <div className='CourseEnterPriseDetails_Container'>
          <div className='CourseDetails_Sec1'>
            <Link to='/home' className='link'>  <div>home</div> </Link>
               <div><img src={ArrowLeftGuide} alt='arrow'/></div>
               <div>Course details</div>
            </div>
             {/* start section 2  */}
             <div className='CourseDetails_Sec2'>
                
                  {/* start part 2  */}
                  <div className='CourseDetails_Sec2_Part2'>
               
                       <div style={{display:'flex',flexDirection:'column'}}>
                       <span className='CourseDetails_Sec2_Part1'>
                  {course?.title}
                      </span>
                     {/* Pass the video URL     */}
                     {course ? (
                           course?.promo_video_link? (
                             <YouTubePlayer videoUrl={course?.promo_video_link} />
                              ) : (
                                <img
                                  src={course?.image || 'default-placeholder.png'}
                                  alt="course"
                                  style={{ width: '650px', maxHeight: '700px' }}
                                />
                              )
                              ) : (
                              <Loading />
                              )}

                           {/* <img src={course?.image} alt='immagecourse'></img>*/}
                             {/* 
                             
                                <div className='CourseDetails_Sec2_Part2_elementSaveCourse'>
                                   <div className='CourseDetails_Sec2_Part2_elementSaveCourse_item'>
                                       <img src={coursesaved} alt='coursesaved'></img> 
                                       <div>save course</div>
                                   </div>
                                    {/* 2 
                                    <div className='CourseDetails_Sec2_Part2_elementSaveCourse_item'>
                                        <img src={calenderGoogle} alt='calenderGoogle'></img>
                                        <div>add to your google calender</div>
                                    </div>
                                    
                            </div>
                             
                             */}
                         
                          
                              {/* Start  Course Content Section */}
                       <div className='CourseDetails_Sec2_Part2_CourseHeader'>
                       <div className='CourseHeader'>
                            <div className={activeSection === 'Description' ? 'active' : '' }
                            onClick={() => setActiveSection('Description')}
                            >Description</div>
                            <div className={activeSection === 'Content'? 'active' : '' }
                            onClick={() => setActiveSection('Content')}
                            >Content</div>
                            <div className={activeSection === 'Course Location' ? 'active' : '' }
                            onClick={() => setActiveSection('Course Location')}
                            >Course Location</div>
                            <div className={activeSection === 'Review'? 'active' : '' }
                            onClick={() => setActiveSection('Review')}
                            >Review</div>
                       </div>
                       <div className='CourseHeader_Body'>  
                         {renderSection()}
                       </div>
                       </div>
                       </div>
                
                       {/* Second Element  */}
                       <div className='CourseDetails_Sec2_Part2_CartElement'>
                             <div className='CourseDetails_Sec2_Part2_CartElement_part1'>
                                 <div className='CartElement_Date'><img src={datestart} alt='datestart'/>Start Date</div>
                                 <div className="date-picker">
                                      {course?.times?.map((timeSlot, index) => (
                                        <div key={index} className="date-option">
                                          <span className="date-text">
                                            {timeSlot.date ? timeSlot.date : ''} , {timeSlot.time ? timeSlot.time : ''}
                                          </span>
                                        </div>
                                      ))}
                                   </div>
                                  {/* start price  */}
                                  <div className='CartElement_ThePrice'>
                                       <div>THE PRICE:</div>
                                       <span>{course?.price} EGP</span>
                                  </div>
                                       {/* buttons  
                                       <div className='CartElement_button_B' onClick={clicktest}>
                                         <div className='CartElement_button_Book'>Book now</div>
                                       </div>
                                       */}
                                         {/* Button Add Cart  */}

           {course?.is_buy===1 ? 
                <div style={{display:'flex', gap:'8px'}}>
                   <img src={purchased} alt='purchased' />
                   <div className='purchased'>purchased</div>
                </div>
                :
                <div className='CartElement_button_C'   onClick={(e) => {
                  e.stopPropagation();
                  handleAddToCartClick(course.id);
                  }}>
                <div className='CartElement_button_Cart'><img src={ShopingCart} alt='ShopingCart'/>
                    {isLoading ? <Spinner /> : 'Add to Cart'}
                </div>
              </div>
                }


                                       
                                              {/* Button Add Cart  */}
                                               {/* 
                                          <div className='CartElement_buy' onClick={clicktest}>
                                           <div className='CartElement_buy_Content'><img src={giveGift} alt='giveGift'/>Buy as a gift</div>
                                         </div>
                                         */}
                                            <div style={{display:'flex',justifyContent:'center'}}><img style={{width:'300px'}} src={Line} alt='Line' /></div>
                                                {/*  Start A card Details    */}
                                                <div className='CartElement_Details'>
                                                       <div className='CartElement_Details_item'><img src={beginner} alt='beginner'/>{course?.level?.name}</div>
                                                       <div className='CartElement_Details_item'><img src={Hours} alt='beginner'/>{course?.course_hours} hours ({course?.session_count} sessions)</div>
                                                       <div className='CartElement_Details_item'><img src={Chairs} alt='Chairs'/>{course?.seats_count} seats left</div>
                                                       <div className='CartElement_Details_item'><img src={Certificate} alt='Certificate'/>{course?.has_certicate === 1  ? 'Certificate' : 'No Certificate'}</div>
                                                       <div className='CartElement_Details_item'>
                                                        {course?.languages.map((lang) => (
                                                       <span key={lang.id}>
                                                       <img src={Arabic} alt={lang.name} /> {lang.name}
                                                       </span>
                                                       ))}
                                                       </div>
                                                       <div className='CartElement_Details_item'><img src={Star} alt='Star'/>{course?.rate } 95% (234)</div>
                                               </div>
                              </div>
                         
                       </div>
                  </div>
               

             </div>
       
   

 
          </div> {/* end container */}
          <Footer />
    </div>
  )
}
export default EnterpriseCourseDetails