import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyCourses } from '../../../store/userFeature/MyCoursesSlice';
import Clock from '../../../assets/images/Course/circle-clock1.png';
import Chair from '../../../assets/images/Course/Chair.png';
import Line from '../../../assets/images/Course/Line.png';
import Start from '../../../assets/images/Course/Star.png';
import PromoYoutube from '../../../assets/images/Course/promo.svg'
const UserMyCourses = () => {
    const dispatch = useDispatch();
    const { courses, loading, error } = useSelector((state) => state.myCourses);
  
    const handleCourseClick = (id) => {
      console.log(id)
     // navigate(`/home/coursedetails/${id}`);
    };
   console.log('test mycourse', courses)
    useEffect(() => {
        dispatch(fetchMyCourses());
      }, [dispatch]);
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
  
  return (
    <div style={{display:'flex', flexWrap:'wrap', alignItems:'center', justifyContent:'center', gap:'12px'}}>
    {courses.length > 0 ? (
      
      courses.map((course) => (
       
        <div
            className='Course_Container'
            key={course.id}
            onClick={() => handleCourseClick(course.id)}
          >
            <div className='Course_Sec1'>
              <img className='Course_Sec1_image' src={course.image} alt='CourseImg' />
            </div>
            <div className='Course_Sec2'>

            <div>start {course.times[0]?.date}</div> {course?.promo_video_link ? <div>watch promo <img src={PromoYoutube} alt='PromoYoutube'></img></div> :""} 
            </div>
            <div className='Course_Sec3'>
              <div className='Course_Sec3_Title'>{course?.title}</div>
            </div>
            <div className='Course_Sec4'>
              <img src={Clock} alt='Clock' /> {course.course_hours} &nbsp; ({course.session_count} sessions)
            </div>
            <div className='Course_Sec5'>
              <img src={Chair} alt='Chair' /> {course.seats_count} seats left
              <img src={Line} alt='Line' />
              <img src={Start} alt='Start' /> {course.rate ? course.rate : '95%'}(234)
            </div>
            <div style={{ paddingTop: '8px', color: 'blue' }} className='Course_Sec6'>
              {course.category.title}
            </div>
            <div className='Course_Sec6'>{course.instructor.name}</div>
            <div className='purchased'>purchased</div>
          </div>
    
      ))
    ) : (
      <p>No courses available</p>
    )}
  </div>
  )
}

export default UserMyCourses