import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// تعريف الـ Async Thunk لجلب الكورسات الخاصة بالمستخدم
export const fetchMyCourses = createAsyncThunk(
  'myCourses/fetchMyCourses',
  async (_, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await axios.get(`${URL}/user-courses`, {
        headers: {token},
      });
      console.log('API Response:', response.data); // ✅ تأكد من شكل البيانات
      return response.data; // تأكد أنك ترجع `data` فقط
    
    } catch (error) {
      return rejectWithValue(error.response?.data || error.msg);
    }
  }
);

const myCoursesSlice = createSlice({
  name: 'myCourses',
  initialState: {
    courses: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload.data.courses; // ← تعديل هنا
      })
      .addCase(fetchMyCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default myCoursesSlice.reducer;
