import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchBranchById } from "../../../store/branches/getbranchbyidSlice";
import { updateBranch, resetUpdateState } from "../../../store/branches/updatebranchSlice";
import { motion } from "framer-motion";
import CountryDropdown from "./Country";
import CityDropdown from "../../InstructorAsUser/InstructorDashboard/City";
import SubcityDropdown from "./Subcity";

const EditBranch = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedBranch } = useSelector((state) => state.getBranchById || {});
  const { loading, success, error } = useSelector((state) => state.updateBranch);

  const [formData, setFormData] = useState({
    branch_name: "",
    phone_branch: "",
    contact_person: "",
    person_position: "",
    phone_person: "",
    address: "",
    country_id: "",
    city_id: "",
    subCity_id: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchBranchById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (selectedBranch) {
      setFormData({
        branch_name: selectedBranch.branch_name || "",
        phone_branch: selectedBranch.phone_branch || "",
        contact_person: selectedBranch.contact_person || "",
        person_position: selectedBranch.person_position || "",
        phone_person: selectedBranch.phone_person || "",
        address: selectedBranch.address || "",
        country_id: selectedBranch.country?.id || "",
        city_id: selectedBranch.city?.id || "",
        subCity_id: selectedBranch?.sub_city?.id || "",
      });
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (success) {
      navigate('/enterprise/home', { state: { activeSection: 'branches' } });
      dispatch(resetUpdateState());
    }
  }, [success, navigate, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCountryChange = (countryId) => {
    setFormData((prevState) => ({
      ...prevState,
      country_id: countryId,
      city_id: "", // Reset city when country changes
      subCity_id: "", // Reset subcity when country changes
    }));
  };

  const handleCityChange = (cityId) => {
    setFormData((prevState) => ({
      ...prevState,
      city_id: cityId,
      subCity_id: "", // Reset subcity when city changes
    }));
  };

  const handleSubcityChange = (subcityId) => {
    setFormData((prevState) => ({
      ...prevState,
      subCity_id: subcityId,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateBranch({ id, formData }));
  };

  return (
    <form className="EnterpriseAddInstructor_Add_Form" onSubmit={handleSubmit}>
      <div className="EnterpriseAddInstructor_Container">
        <div className="EnterpriseAddInstructor_header" style={{ color: "#000" }}>
          Edit Branch
        </div>

        {error && <p style={{ color: "red" }}>{error}</p>}
        {loading && <p>Updating...</p>}

        <div className="EnterpriseInformation_Section">
          <label>Branch Name</label>
          <input type="text" name="branch_name" value={formData.branch_name} onChange={handleChange} />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>Phone</label>
          <input type="text" name="phone_branch" value={formData.phone_branch} onChange={handleChange} />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>Contact Person</label>
          <input type="text" name="contact_person" value={formData.contact_person} onChange={handleChange} />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>Person Position</label>
          <input type="text" name="person_position" value={formData.person_position} onChange={handleChange} />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>Phone (Contact Person)</label>
          <input type="text" name="phone_person" value={formData.phone_person} onChange={handleChange} />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>Address</label>
          <input type="text" name="address" value={formData.address} onChange={handleChange} />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>Country</label>
          <CountryDropdown name="country_id" value={formData.country_id} onCountryChange={handleCountryChange} />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>City</label>
          <CityDropdown name="city_id" selectedCity={formData.city_id} value={formData.city_id} onCityChange={handleCityChange} />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>SubCity</label>
          <SubcityDropdown name="subCity_id" selectedSubcity={formData.subCity_id}  cityId={formData.city_id}  value={formData.subCity_id} onSubcityChange={handleSubcityChange} />
        </div>

        <motion.button type="submit" disabled={loading}  className="InstructorPage_SaveButton">
          {loading ? "Updating..." : "Save Changes"}
        </motion.button>
      </div>
    </form>
  );
};

export default EditBranch;
