import React, { useState } from 'react';
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar';
import Signup from '../../component/Signup/Signup';
import Login from '../../component/Login/Login';
import Footer from '../../component/Footer/Footer';
import CourseDetails from './CourseDetails';
import UserNavbar from '../../component/UserNavbar/UserNavbar';
import Cookies from 'js-cookie'; // Import Cookies
import logo from '../../assets/images/lndexlogo_svg.svg'

const IndexInstructor = () => {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const token = Cookies.get('authToken'); // Get the token from cookies

  return (
    <div>
      {/* Render UserNavbar if token exists, else UnauthAppbar */}
      {token ? (
        <div style={{display:'flex', justifyContent:'space-around'}}>
          <img src={logo} alt='logo'/>
          <UserNavbar />
        </div>
       
      ) : (
        <UnauthAppbar
          showSignup={showSignup}
          setShowSignup={setShowSignup}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
        />
      )}

      <CourseDetails />
      <Footer />
    </div>
  );
};

export default IndexInstructor;
