import React, { useState } from 'react';
import './EnterpriseAddInstructor.css';
import UploadIcon from '../../assets/images/UploadPhoto.svg';
import SaveIcon from '../../assets/images/instructors/save.svg';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { storeInstructor, clearSuccess, clearError } from '../../store/enterpriseFeature/storeInstructorEnterpriseSlice';
import { useNavigate } from 'react-router-dom';




const EnterpriseAddInstructor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, success, error } = useSelector((state) => state.storeInstructorEnterprise);
  const [errors, setErrors] = useState({});
  // Local state for form inputs
  const [formData, setFormData] = useState({
    name: '',
    about_details: '',
    profile_image: null,
  });

  // Preview image state
  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      const file = files[0];

      // 🔹 التحقق من نوع الملف
      if (!file.type.startsWith("image/")) {
        setErrors({ ...errors, profile_image: 'Only image files are allowed!' });
        return;
      }

      // 🔹 التحقق من حجم الملف (2MB)
      if (file.size > 2 * 1024 * 1024) {
        setErrors({ ...errors, profile_image: 'Image size must be less than 2MB!' });
        return;
      }

      setFormData({ ...formData, profile_image: file });
      setImagePreview(URL.createObjectURL(file));
      setErrors({ ...errors, profile_image: '' });
    } else {
      // 🔹 إزالة المسافات الفارغة في بداية النص
      const trimmedValue = value.trimStart();
      setFormData({ ...formData, [name]: trimmedValue });
      setErrors({ ...errors, [name]: '' });
    }

    // 🔹 إعادة تعيين رسالة النجاح عند التعديل
    if (success) dispatch(clearSuccess());
  };

  
  // Handle form submission
  const handleSubmit =  async  (e) => {
    e.preventDefault();
    const { name, about_details, profile_image } = formData;

    // Validate fields
    const newErrors = {};
    if (!name) newErrors.name = 'Name field is required.';
    if (!about_details) newErrors.about_details = 'Bio field is required.';
    if (!profile_image) newErrors.profile_image = 'Profile image is required.';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);  // If errors exist, set them in state
      return; // Stop form submission
    }

    try {
      // Dispatch and wait for response
      await dispatch(storeInstructor({ name, about_details, profile_image })).unwrap();
  
      // التنقل بعد نجاح التسجيل
      navigate('/enterprise/home', { state: { activeSection: 'instructor' } });
  
      // إعادة تحميل الصفحة بعد تنقل ناجح
      setTimeout(() => {
        window.location.reload();
      }, 200);
      
    } catch (error) {
      console.error("Error registering instructor:", error);
      // يمكنك إضافة إشعار أو رسالة خطأ هنا
    }
  };

  // Clear success or error messages after display
  const clearMessages = () => {
    if (success) dispatch(clearSuccess());
    if (error) dispatch(clearError());
  };

  return (
    <form className="EnterpriseAddInstructor_Add_Form" onSubmit={handleSubmit}>
      <div className="EnterpriseAddInstructor_Container">
        <div className="EnterpriseAddInstructor_header">Add Instructor Info</div>

        {/* Upload Instructor Photo */}
        <div className="EnterpriseAddInstructor_UploadImage">
          <div>Upload Instructor photo:</div>
          <div className="Upload_icon_section">
            <label htmlFor="upload-input">
              {imagePreview ? (
                <img src={imagePreview} alt="Uploaded" className="uploaded_aadimage" />
              ) : (
                <img src={UploadIcon} alt="UploadIcon" />
              )}
            </label>
            <div>Click to upload image</div>
            <input
              id="upload-input"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              name="profile_image"
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Name Section */}
        <div className="EnterpriseAddInstructor_Section">
          <div>
            Instructor Name <span className="required">*</span>
          </div>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <p className="error-message-enterpriseform">{errors.name}</p>}
        </div>

        {/* About Section */}
        <div className="EnterpriseAddInstructor_Section">
          <div>
            Instructor Bio <span className="required">*</span>
          </div>
          <textarea
            type="text"
            name="about_details"
            rows="4" 
            maxLength="300"
            value={formData.about_details}
            onChange={handleChange}
            required
          ></textarea>
          {errors.about_details && <p className="error-message-enterpriseform">{errors.about_details}</p>}
        </div>

        {/* Profile Image Error */}
        {errors.profile_image && <p className="error-message-enterpriseform">{errors.profile_image}</p>}
      </div>

      {/* Submit Button */}
      <button type="submit" className="InstructorPage_SaveButton">
        {loading ? (
          <motion.div
            className="spinner"
            animate={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              duration: 1,
              ease: 'linear',
            }}
          >
            <motion.div
              className="spinner-inner"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1.2 }}
              transition={{
                repeat: Infinity,
                duration: 0.5,
                ease: 'easeInOut',
              }}
            />
          </motion.div>
        ) : (
          <>
            <img src={SaveIcon} alt="saveicon" /> Save
          </>
        )}
      </button>

      {/* Messages */}
      {success && <p className="success-message">{success}</p>}
      {error && <p className="error-message">{error}</p>}

      {/* Clear Messages Button */}
      {(success || error) && (
        <button type="button" style={{ display: 'none' }} onClick={clearMessages}>
          Clear Messages
        </button>
      )}
    </form>
  );
};

export default EnterpriseAddInstructor;
