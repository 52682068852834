import React, { useState } from 'react';
import SaveIcon from '../../../assets/images/instructors/save.svg';
import CountryDropdown from './Country';
import CityDropdown from '../../InstructorAsUser/InstructorDashboard/City';
import SubcityDropdown from './Subcity';
// link with redux-toolkit 
import { fetchStoreBranches } from '../../../store/branches/StorebrancheSlise';
import { useSelector , useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";



const CreateBranch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, error } = useSelector((state) => state.storeBranches);
  const [formData, setFormData] = useState({
    branch_name: '',
    phone_branch: '',
    contact_person: '',
    person_position: '',
    phone_person: '',
    address: '',
    country_id:'1',
    city_id:'',
    subCity_id:''
  });

  const [errors, setErrors] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Assuming 'en' for English
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,  // in React prevState ======> is the current state 
      [name]: value,
    }));
    // Clear error for the field if any
    if (errors[name]) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  };

// Handle country selection
const handleCountryChange = (countryId) => {
  setFormData(prevState => ({
    ...prevState,
    country_id: countryId,
    city_id: '', // Reset city when country changes
  }));
};

// Handle city selection
const handleCityChange = (cityId) => {
  setFormData(prevState => ({
    ...prevState,
    city_id: cityId
  }));
};

// Handle subcity selection
const handleSubcityChange = (subcityId) => {
  setFormData(prevState => ({
    ...prevState,
    subCity_id: subcityId
  }));
};




  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate fields (feel free to adjust validation as needed)
    if (!formData.branch_name) newErrors.branch_name = 'Branch name is required.';
    if (!formData.phone_branch) newErrors.phone_branch = 'Branch phone is required.';
    if (!formData.contact_person) newErrors.contact_person = 'Contact person is required.';
    if (!formData.person_position) newErrors.person_position = 'Person position is required.';
    if (!formData.phone_person) newErrors.phone_person = 'Person phone is required.';
    if (!formData.address) newErrors.address = 'Address is required.';
    if (!formData.country_id) newErrors.country_id = 'Country is required.';
    if (!formData.city_id) newErrors.city_id = 'City is required.';
    if (!formData.subCity_id) newErrors.subCity_id = 'Subcity is required.';
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // For testing purposes: log the form data
    console.log('Form Data After submit:', formData);

    console.log('Form Data After submit:', formData);
    dispatch(fetchStoreBranches(formData)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        navigate('/enterprise/home', { state: { activeSection: 'branches' } });
      }
    });
    setErrors({});
  };

  return (
    <form className="EnterpriseAddInstructor_Add_Form" onSubmit={handleSubmit}>
      <div className="EnterpriseAddInstructor_Container">
        <div className="EnterpriseAddInstructor_header" style={{color:'#000'}}>Create New Branch</div>


   {/* branch_Support  */}
   <div className='EnterpriseInformation_Enterprise_Support'>

              <div style={{color:'#000', fontSize:'20px', fontWeight:'700'}}>
                <span>Customer Support Agent info</span> 
              </div>

               {/* Contact Person */}
        <div className="EnterpriseInformation_Section">
          <label>
            Contact Person <span className="required">*</span>
          </label>
          <input
            type="text"
            style={{background:'#fff'}}
            name="contact_person"
            value={formData.contact_person}
            onChange={handleChange}
            placeholder="Enter contact person's name"
          />
          {errors.contact_person && <p style={{width:'100%'}} className="error-message-enterpriseform">{errors.contact_person}</p>}
        </div>

        {/* Person Position */}
        <div className="EnterpriseInformation_Section">
          <label>
            Person Position <span className="required">*</span>
          </label>
          <input
            type="text"
            style={{background:'#fff'}}
            name="person_position"
            value={formData.person_position}
            onChange={handleChange}
            placeholder="Enter position"
          />
          {errors.person_position && <p style={{width:'100%'}} className="error-message-enterpriseform">{errors.person_position}</p>}
        </div>

        {/* Person Phone */}
        <div className="EnterpriseInformation_Section">
          <label>
            Person Phone <span className="required">*</span>
          </label>
          <input
            type="text"
            style={{background:'#fff'}}
            name="phone_person"
            value={formData.phone_person}
            onChange={handleChange}
            placeholder="Enter phone number"
          />
          {errors.phone_person && <p style={{width:'100%'}} className="error-message-enterpriseform">{errors.phone_person}</p>}
        </div>



</div> 


        {/* Branch Name */}
        <div className="EnterpriseInformation_Section">
          <label>
            Branch Name <span className="required">*</span>
          </label>
          <input
            type="text"
            name="branch_name"
            value={formData.branch_name}
            onChange={handleChange}
            placeholder="Enter branch name : (Enterprise Maadi)"
          />
          {errors.branch_name && <p style={{width:'100%'}} className="error-message-enterpriseform">{errors.branch_name}</p>}
        </div>

        {/* Branch Phone */}
        <div className="EnterpriseInformation_Section">
          <label>
            Branch Phone <span className="required">*</span>
          </label>
          <input
            type="text"
            name="phone_branch"
            value={formData.phone_branch}
            onChange={handleChange}
            placeholder="Enter branch phone"
          />
          {errors.phone_branch && <p style={{width:'100%'}} className="error-message-enterpriseform">{errors.phone_branch}</p>}
        </div>

      

        {/* Address */}
        <div className="EnterpriseInformation_Section">
          <label>
            Address <span className="required">*</span>
          </label>
          <input
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="Enter address"
          ></input>
          {errors.address && <p style={{width:'100%'}} className="error-message-enterpriseform">{errors.address}</p>}
        </div>


         <div style={{display:'flex', justifyContent:'space-between',width:'100%',padding:'8px'}}>
              {/* Country Dropdown */}
        <div className="EnterpriseInformation_Section">
           <label>
             Country <span className="required">*</span>
           </label>
          <CountryDropdown 
            selectedCountry={formData.country_id} 
            onCountryChange={handleCountryChange} 
            language={selectedLanguage}
          />
          {errors.country_id && <p className="error-message-enterpriseform">{errors.country_id}</p>}
        </div>

      {/* City Dropdown */}
      <div className="EnterpriseInformation_Section">
          <label>
            City <span className="required">*</span>
          </label>
          <CityDropdown 
            selectedCity={formData.city_id} 
            onCityChange={handleCityChange} 
            language={selectedLanguage}
          />
          {errors.city_id && <p className="error-message-enterpriseform">{errors.city_id}</p>}
        </div>

        </div>

        {/* Subcity Dropdown - Only show if city_id is selected */}
        {formData.city_id && (
          <div className="EnterpriseInformation_Section">
            <label>
              Subcity <span className="required">*</span>
            </label>
            <SubcityDropdown 
              selectedSubcity={formData.subCity_id} 
              onSubcityChange={handleSubcityChange} 
              cityId={formData.city_id} 
              language={selectedLanguage}
            />
            {errors.subcity_id && <p style={{width:'100%'}} className="error-message-enterpriseform">{errors.subcity_id}</p>}
          </div>
        )}

      </div>





      {/* Submit Button */}
      <motion.button 
        type="submit" 
        className="InstructorPage_SaveButton"
        disabled={status === 'loading'}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {status === 'loading' ? 'Saving...' : (
          <>
            <img src={SaveIcon} alt="Save Icon" /> Save
          </>
        )}
      </motion.button>
      {/* في حال وجود خطأ من الـ Redux، يتم عرضه هنا */}
      {error && (
          <div className="error-message" style={{ color: 'red', marginBottom: '1rem' }}>
            {error.message || 'حدث خطأ أثناء حفظ البيانات.'}
          </div>
        )}
    </form>
  );
};

export default CreateBranch;
