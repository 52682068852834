import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../config';

const Forgetpassword = () => {
  const [inputs, setInputs] = useState(["", "", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(200);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [error, setError] = useState("");
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  // Timer management
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [timeLeft]);

  const handleChange = (index, value) => {
    if (value.length > 1) return;
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    if (value && index < 4) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !inputs[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{5}$/.test(pastedData)) {
      const newInputs = pastedData.split("");
      setInputs(newInputs);

      newInputs.forEach((digit, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].value = digit;
        }
      });
      inputRefs.current[4]?.focus();
    } else {
      alert("Please paste a valid 5-digit OTP code.");
    }
  };

  const handleResend = () => {
    setInputs(["", "", "", "", ""]);
    setTimeLeft(200);
    setIsResendDisabled(true);
    console.log("Resending Verification Code...");
  };

  const handleSubmit = async () => {
    try {
      const otpCode = inputs.join("");
      const response = await axios.post(`${URL}/check-forget-code`, {
        otp_code: otpCode,
      });
      console.log("Password reset link sent:", response.data);
      navigate("/ChangePassword", { state: { otp_code: otpCode } });
    } catch (err) {
      setError("This Code is not valid.");
      console.error("Error:", err);
    }
  };


  return (
    <div className="Otp-Container-page">
      <div className="verification-container">
        <img src={Logo} alt="lndex Logo" />
        <div className="Otp-info-section">
          <p className="subtitle">Enter Verification Code</p>
          <p className="instruction">Please check your email for the verification code.</p>
        </div>

        <div className="code-inputs">
          {inputs.map((input, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={input}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={index === 0 ? handlePaste : null}
              ref={(el) => (inputRefs.current[index] = el)}
              className="code-input"
            />
          ))}
        </div>

        <div className="timer">
          {timeLeft > 0 ? (
            <span className="time-left">{timeLeft} Sec</span>
          ) : (
            <button
              onClick={handleResend}
              disabled={isResendDisabled}
              className="resend-btn"
            >
              Resend Verification Code
            </button>
          )}
        </div>

        {error && <p className="error-message">{error}</p>}

        <div className="otp-sent-container">
          <button onClick={handleSubmit} className="send-btn">Send</button>
        
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
