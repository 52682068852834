import React from 'react'
import { useParams } from 'react-router-dom';
const CategoryFilter = () => {
    const { id } = useParams();
  return (
    <div>
       Filter Course By Category
       <h2>Category ID: {id}</h2>
    </div>
  )
}

export default CategoryFilter