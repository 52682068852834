// getbranchbyidSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URL } from "../../config";
import Cookies from "js-cookie";

// جلب بيانات فرع واحد
export const fetchBranchById = createAsyncThunk(
  "branches/fetchBranchById",
  async (branchId, { rejectWithValue }) => {
    try {
      const token = Cookies.get("entoken"); // Get token from cookies
      const response = await axios.get(`${URL}/enterprise/branches/edit/${branchId}`, {
        headers: {
          token,
        },
      });
      return response.data.data.branch;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message || "Something went wrong");
    }
  }
);

const branchSlice = createSlice({
  name: "branches",
  initialState: {
    branches: [],
    selectedBranch: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBranchById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBranchById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedBranch = action.payload;
        state.error = null;
      })
      .addCase(fetchBranchById.rejected, (state, action) => {
        state.loading = false;
        state.error = String(action.payload);
      });
  },
});

export default branchSlice.reducer;
