import React, { useState } from 'react';
import axios from 'axios';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../config';
import Error from '../../component/Bootstrap/Error_Message';

const Forgetpassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${URL}/forget-password`, {
        email,
      });
      console.log('Password reset link sent:', response.data);
      navigate('/verfiy-code');
      // Handle successful response here
    } catch (err) {
      setError('This account does not exist.');
      console.error('Error:', err);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className='Login_page_container'>
      {/* Start signup body */}
      <div className='Signup_body'>
        <div className='Signup_body_Form'>
          <div className='Signup_body_Form_Sec1'>
            <img src={Logo} alt='Logo' />
          </div>
          <div className='Signup_body_Form_Sec2'>Forgot password</div>
          <div className='Signup_body_Form_Sec3'>
            <hr />
          </div>
          {/* Start input Field */}
          <div className='Signup_body_input_container'>
            {/* Email */}
            <div className='Signup_body_Form_Sec4'>
              <div>Email</div>
              <div className='Signup_body_input'>
                <input
                  className='Sign_input'
                  type='email'
                  placeholder='ayamustafa@gmail.com'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown} // Listen for Enter key
                />
              </div>
            </div>
          </div>
          {/* End input Field Container */}
          <div className='Registration_body_Form_Sec5'>
            <button
              className='Signup_body_Form_Sec5_button'
              onClick={handleSubmit}
            >
              Send
            </button>
          </div>
          <div className='Signup_body_Form_Sec3'>
            <hr />
            {error && <Error Message={error} />}
          </div>
        </div>
        {/* End Form */}
      </div>
    </div>
  );
};

export default Forgetpassword;
