import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EnterpriseCreateCourse"; 
import { URL } from "../../../config";

const LanguageSelect = ({ onSelectionChange }) => {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(`${URL}/languages`);
        if (response.data.status) {
          setLanguages(response.data.data.languages);
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const handleSelect = (language) => {
    if (!selectedLanguages.some((selected) => selected.id === language.id)) {
      const updatedSelection = [...selectedLanguages, language];
      setSelectedLanguages(updatedSelection);
      onSelectionChange(updatedSelection.map((lang) => lang.id));
    }
  };

  const handleRemove = (languageId) => {
    const updatedSelection = selectedLanguages.filter(
      (language) => language.id !== languageId
    );
    setSelectedLanguages(updatedSelection);
    onSelectionChange(updatedSelection.map((lang) => lang.id));
  };

  return (
    <div className="InstructorCreateCourse_form_Section_input">
      <div className="selected-languages">
        {selectedLanguages.map((language) => (
          <div key={language.id} className="selected-language">
            {language.name}
            <button
              className="remove-button"
              onClick={() => handleRemove(language.id)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>

      <div className="dropdown">
        <button
        type="button"
          className="dropdown-toggle"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          role="button"
          tabIndex={0}
        >
          {dropdownOpen ? "Close" : "Select Languages"}
        </button>
        {dropdownOpen && (
          <ul className="InstructorCreateCourse_form_Section_input">
            {languages.map((language) => (
              <li
                key={language.id}
                className="dropdown-item"
            
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  e.stopPropagation(); // Prevent event bubbling
                  handleSelect(language);
                }}
              >
                {language.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LanguageSelect;
