import React, { useState } from 'react';
import './FAQsComponent.css';
import DeleteIcon from '../../assets/images/DeleteCourse.svg';

const FAQsComponent = ({ faqs, setFaqs }) => {
  const [newFaq, setNewFaq] = useState({ question: '', answer: '' });
  const [errors, setErrors] = useState({ question: '', answer: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFaq((prevFaq) => ({
      ...prevFaq,
      [name]: value,
    }));

    // إزالة الخطأ إذا بدأ المستخدم في الكتابة
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? '' : prevErrors[name]
    }));
  };

  const handleAddFaq = (event) => {
    event.preventDefault();

    // التحقق من أن الحقول ليست فارغة
    const newErrors = {
      question: newFaq.question.trim() ? '' : 'Question is required.',
      answer: newFaq.answer.trim() ? '' : 'Answer is required.',
    };

    setErrors(newErrors);

    // إذا كان هناك خطأ، لا تكمل الإضافة
    if (newErrors.question || newErrors.answer) {
      return;
    }

    // إضافة السؤال
    setFaqs((prevFaqs) => [...prevFaqs, newFaq]);
    setNewFaq({ question: '', answer: '' });
  };

  const handleRemoveFaq = (index) => {
    setFaqs((prevFaqs) => prevFaqs.filter((_, i) => i !== index));
  };

  return (
    <div className="faqs-container">
      <div className="InstructorCreateCourse_form_Section_title">FAQs <span className='Mandatory_star'>*</span></div>
      <div className="faqs-form">
        <input
          type="text"
          name="question"
          placeholder="Enter question"
          value={newFaq.question}
          onChange={handleInputChange}
          className="faq-input"
          maxLength={255}
        />
        {errors.question && <p style={{color:'red'}}>{errors.question}</p>} {/* عرض خطأ السؤال */}

        <textarea
          name="answer"
          placeholder="Enter answer"
          value={newFaq.answer}
          onChange={handleInputChange}
          className="faq-textarea"
          maxLength={255}
        />
        {errors.answer && <p style={{color:'red'}}>{errors.answer}</p>} {/* عرض خطأ الإجابة */}

        <div className="faq-component-container">
          <button 
            onClick={handleAddFaq} 
            className="faq-add-button"
          >
            + Add New FAQs
          </button>
        </div>
      </div>
      <ul className="faqs-list">
        {faqs.map((faq, index) => (
          <li key={index} className="faq-item">
            <div>
              <strong>Question Item:</strong> 
              {faq.question.length > 100 
                ? `${faq.question.substring(0, 80)}...` 
                : faq.question}
              <br />
            </div>
            <img 
              src={DeleteIcon} 
              onClick={() => handleRemoveFaq(index)} 
              className="faq-delete-icon"
              alt="Delete FAQ"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQsComponent;
