import React , {useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '../../assets/images/instructors/add.svg';
import Loading from '../../component/Loading/Loading'
import Line from '../../assets/images/Line.svg'
import { motion } from 'framer-motion';
import { useState } from 'react';
import CourseEnterprise from '../../component/courseDashboard/CourseEnterprise';
import { useDispatch , useSelector } from 'react-redux';
import { fetchEnterpriseCourses } from '../../store/enterpriseFeature/enterpriseCoursesSlice';
import NewUserCourseNotification from './NewUserCourseNotification';

const EnterpriseCourseComponent = ({ flag , branch }) => {
    const [loading , setloading ] = useState()
    const [localCourses, setLocalCourses] = useState([]); 
    const [showNotification, setShowNotification] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Mock courses data
    const { courses, status, error } = useSelector((state) => state.enterpriseCourses);
    console.log('bugging',flag , branch.length)
     // Fetch courses on component mount
  useEffect(() => {
    dispatch(fetchEnterpriseCourses(0)); // Fetch non-archived courses
  }, [dispatch]);

  useEffect(() => {
    setLocalCourses(courses); // مزامنة الكورسات المحلية مع الكورسات من الـ Redux
  }, [courses]);

  // دالة لحذف الكورس من القائمة المحلية
  const handleCourseDeleted = (courseId) => {
    setLocalCourses((prevCourses) =>
      prevCourses.filter((course) => course.id !== courseId)
    );
  };

  // دالة لإدارة الكورسات المؤرشفة
  const handleCourseArchived = (courseId) => {
    setLocalCourses((prevCourses) =>
      prevCourses.filter((course) => course.id !== courseId)
    );
  };

      // دالة التنقل عند الضغط على "Create New Course"
      const handleCreateCourseClick = () => {
        if (flag === 0) {
            setShowNotification(true); // عرض الإشعار
        } else {
          navigate('/enterprise/create-course', { state: { branchLength: branch.length } });
        }
    };

    //console.log('Courses:', courses);
    //console.log('Status:', status);
    //console.log('Error:', error);
  return (
    <div className="container-fluid">
        
    <div className='row mb-3'>
 
      <div className="col-lg-11 d-flex justify-content-end" style={{position:'relative',top:'-50px'}}>
  
          <button className='InstructorHome_AddCourse_Button' onClick={handleCreateCourseClick}>
            <img src={AddIcon} alt='AddTheIcon' className='me-2' />
             Create New Course
          </button>
    
      </div>
      
    </div>
     <div className='Line_instractor_Dashboard'>
         <img src={Line} alt='Line' />
     </div>
  
    <div className='Courses_Instructor_Container'>
      {loading && <Loading />}
      {error && <div>Error: {error}</div>}
      {localCourses.length > 0 ? (
          localCourses.map((course) => (
            <motion.div
              key={course.id}
              initial={{ opacity: 0, y: -80 }}
              animate={{ opacity: 1, y: 5 }}
              transition={{ duration: 1.2, delay: 1 * 0.2 }}
              style={{ marginBottom: '20px' }}
            >
              <CourseEnterprise
                course={course}
                onCourseDeleted={handleCourseDeleted} // تمرير الدالة
                onCourseArchived={handleCourseArchived} // تمرير الدالة
              />
            </motion.div>
          ))
      ) : (
        <motion.div
        initial={{ opacity: 0, y: -80 }} // حالة البداية
        animate={{ opacity: 1, y: 5 }} // حالة التفعيل
        exit={{ opacity: 0, y: 20 }} // حالة الخروج
        transition={{ duration: 1.5 }} // مدة الحركة
        style={{display:'flex' , justifyContent:'center' , flexDirection:'column', textAlign: 'center', fontSize: '20px', margin: '20px 0' }}
      >
        <p>No courses are currently available.</p>
        <p>If you've recently created courses, please wait for activation.</p>
        <p>Feel free to check back later!</p>
      </motion.div>

      )}
    </div>
              {/* إضافة إشعار عند منع إنشاء الكورس */}
              {showNotification && <NewUserCourseNotification showNotification={showNotification} onClose={() => setShowNotification(false)} />}

  </div>
  )
}

export default EnterpriseCourseComponent