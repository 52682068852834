import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEnterpriseProfile } from '../../store/enterpriseFeature/profileEnterpriseSlice';
import { updateEnterpriseImage } from '../../store/enterpriseFeature/profilepictureEnterprise';
import EditInstructorIcon from '../../assets/images/instructors/Icon-Edit.svg'
import PasswordIcon from '../../assets/images/passwordIcon.svg'
import CameraIcon from '../../assets/images/CameraIcon.svg'
import SaveIcon from '../../assets/images/instructors/save.svg'
import { useLocation , useNavigate  } from 'react-router-dom'
import EnterpriseChangePassword from './EnterpriseChangePassword'
import EnterpriseCourseComponent from './EnterpriseCourseComponent'
import { motion } from 'framer-motion';
import ArchiveEnterprise from './ArchiveEnterprise';
import EnterpriseAbout from './EnterpriseAbout';
import NewUserNotification from './NewUserNotification';
import EnterpriseInstructorComponent from './EnterpriseInstructorComponent';
import EnterpriseGallery from './EnterpriseGallery';
import BranchesComponent from './branches/BranchesComponent';
const EnterpriseHome = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);
 const [selectedImage, setSelectedImage] = useState(null);
 const [fileToUpload, setFileToUpload] = useState(null); // Store the selected file
 const [isLoading, setIsLoading] = useState(false); // Track loading state
  // Mock profile object

   // Load profile from Redux
   const { profile, loading, error } = useSelector((state) => state.enterpriseProfile);
   const Enterpricedata = profile?.data?.enterprise ;
   console.log(Enterpricedata)
{/*  */}

useEffect(() => {
  // Check if the user is new from localStorage
  const userStatus = localStorage.getItem('userStatus');
  if (userStatus === 'newuser') {
    setShowNotification(true);
  } else {
    setShowNotification(false);
  }
}, []);


 const [activeSection, setActiveSection] = useState(location.state?.activeSection || '');

    const renderSection = () => {
        switch (activeSection) {
          case 'Courses':
            return   <EnterpriseCourseComponent branch={Enterpricedata.branches} flag={Enterpricedata.instructor_count}/> ;
          
          case 'About':
            return <EnterpriseAbout EnterpriseAbout={Enterpricedata.about_details}/>;
          case 'Archive':
             return  <ArchiveEnterprise /> ;   
          case 'password':
             return  <EnterpriseChangePassword /> ;   
          case 'instructor':
             return  <EnterpriseInstructorComponent /> ;   
          case 'Enterprise Gallery':
             return  <EnterpriseGallery /> ;   
          case 'branches':
             return <BranchesComponent image={Enterpricedata?.profile_image}/> ;    
          default:
            return null;
        }
      };



const handleSaveImage = () => {
  if (fileToUpload) {
    setIsLoading(true); // Start loading
    const formData = new FormData();
    formData.append('profile_image', fileToUpload);

    // Dispatch the updateEnterpriseImage action
    dispatch(updateEnterpriseImage(formData))
      .then(() => {
        console.log('Image uploaded successfully');
        setIsLoading(false); // Stop loading
        window.location.reload(); // Reload the page
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
        setIsLoading(false); // Stop loading even on error
      });
  }
};


    // navigate to Edit Profile Instructor
    const handleEditInfoClick = () => {
        navigate('/enterprise/Information-form')
       console.log('handle Edit Click ')
      };


  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFileToUpload(file); // Store the file for later uploading
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchEnterpriseProfile());
      } catch (error) {
        console.error("Error fetching enterprise profile:", error);
      }
    };
  
    fetchData();
  }, [dispatch]);
  
  // داخل مكون العرض
  // Loading state
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, repeat: Infinity, repeatType: 'reverse' }}
          style={{ fontSize: '24px', fontWeight: 'bold', color: '#007bff' }}
        >
          Loading profile...
        </motion.div>
      </div>
    );
  }
  
  if (error) {
    return <div>Error loading profile: {error.message}</div>;
  }
  
  // هنا يمكن استخدام profile بأمان
  
  if (!profile) {
    return <div>No profile data available.</div>;
  }

  return (
    <div className='InstructorHome_Container'>
              {/* all page divided as Sections*/}  
              {/* Section One "Welcome Instructor" */}  
              <div className='InstructorHome_Section1'> 
        <motion.div
          initial={{ opacity: 0, x: -20, rotate: -5 }} // البداية
          animate={{ opacity: 1, x: 0, rotate: 0 }} // التحرك إلى الوضع النهائي
          transition={{ duration: 0.5 }} // مدة الحركة
          whileHover={{ scale: 1.25, rotate: 2.5 }} // تأثير عند التحويم
        >
          Welcome {profile?.data?.enterprise?.name} !
        </motion.div>
        <div className='InstructorPage_Sec1_Part1'></div>
      </div>
        
           {/* Section Two select from instructor Profile  "Welcome Instructor" */}  

          <div className='InstructorHome_Sec2_Container'>
           <div style={{display:'flex',alignItems:'center',gap:'32px'}}>
           <div className='InstructorPage_Sec2_Part1'>
            {/* Clickable profile image for uploading new image */}
            <label htmlFor='upload-profile-image' style={{ cursor: 'pointer' }}>
              <img
                className='profile_image_instructor'
                src={selectedImage ?  selectedImage : Enterpricedata?.profile_image }
                alt='InstructorImage'
              />
              <img src={CameraIcon} alt='Cameraicon' className='CameraIcon'/>
            </label>
            <input
              type='file'
              id='upload-profile-image'
              style={{ display: 'none' }}
              onChange={handleImageChange}
              accept='image/*'
            />
          </div>



                      <div className='InstructorPage_Sec2_part2'>
                             <div>
                                 <div className='InstructorPage_Sec2_part2_name'>
                                 <motion.div
                                    initial={{ opacity: 0, x: -20, rotate: -5 }} // البداية
                                    animate={{ opacity: 1, x: 0, rotate: 0 }} // التحرك إلى الوضع النهائي
                                    transition={{ duration: 0.5 }} // مدة الحركة
                              
                                 >
                                  {Enterpricedata?.name}
                                  </motion.div>                          
                                  
                                  </div>
                                 <div className='InstructorPage_Sec2_part2_Major'>{Enterpricedata?.specialization ? Enterpricedata?.specialization : <a href='/enterprise/Information-form'>please Complete your Information</a>}</div>
                                 <div className='InstructorPage_Sec2_part2_JoinDate'>Joined  {Enterpricedata?.join_date}</div>
                             </div>
                             {showNotification && (
                            <NewUserNotification 
                             showNotification={showNotification}
                             message="You need to complete your profile." 
                            onClose={() => setShowNotification(false)} 
                            />
                            )}
                             <div className='InstructorPage_Sec2_part3_button_Container'>
                            
                                <button className='InstructorPage_Sec2_part2_EditInfo'    onClick={handleEditInfoClick}><img src={EditInstructorIcon} alt='editIcon'/> Edit My Info</button>
                                <button className='InstructorPage_Sec2_part2_ChangePassword'    onClick={() => setActiveSection('password')}><img src={PasswordIcon} alt='PasswordIcon'/> Change password </button>
                             </div>
                            
                                  {/* Display Save button after an image is selected */}
                                  {fileToUpload && (
                                     <button className='InstructorPage_SaveButton' onClick={handleSaveImage}>
                                  {isLoading ? (
          // Loading animation using Framer Motion
          <motion.div
            className="spinner"
            animate={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              duration: 1,
              ease: "linear",
            }}
          >
            <motion.div
              className="spinner-inner"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1.2 }}
              transition={{
                repeat: Infinity,
                duration: 0.5,
                ease: "easeInOut",
              }}
            />
          </motion.div>
        ) : (
          <>
            <img src={SaveIcon} alt="saveicon" /> Save New Image
          </>
        )}
                                     </button>
                                      )}
                     </div>
                     </div>
                                 {/* Section 2 Right Side  */}
                              <div className='InstructorPage_Sec2_part2_RightSide'>
                              <div className='InstructorPage_Sec2_part2_RightSide_Email'>
                              {Enterpricedata?.website_link ? (
    <a 
      href={Enterpricedata?.website_link.startsWith("http") 
        ? Enterpricedata?.website_link 
        : `mailto:${Enterpricedata?.website_link}`} 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: 'inherit' }} // اختياري: لتنسيق الرابط بنفس تنسيق النص
    >
      {Enterpricedata?.website_link}
    </a>
  ) : (
    "No LinkedIn or Email provided"
  )}
                              </div>
                              <div className='InstructorPage_Sec2_part2_RightNumbers'>
                                   <div className='InstructorPage_Sec2_part2_RightNumbers_Items'><span>{Enterpricedata?.courses_count}</span> <span>courses</span> </div>
                                   <div className='InstructorPage_Sec2_part2_RightNumbers_Items'><span>{Enterpricedata?.instructor_count}</span> <span>Instructors</span> </div>
                              </div>
                                </div>   
                                
                     </div> 
                       {/* Section 3 and 4  */}
                        {/*  Start Section 4 */}
                      <div className='InstructorHomePage_Sec4'>
                      <div className={activeSection === 'Courses' ? 'active' : '' }
                      onClick={() => setActiveSection('Courses')}
                      >Courses</div>
                      <div className={activeSection === 'About' ? 'active' : '' }
                      onClick={() => setActiveSection('About')}
                      >About</div>
                      <div className={activeSection === 'Review'? 'active' : '' }
                      onClick={() => setActiveSection('Review')}
                      >Review</div>
                        <div className={activeSection === 'Archive'? 'active' : '' }
                      onClick={() => setActiveSection('Archive')}
                      >Archive</div>
                         <div className={activeSection === 'instructor'? 'active' : '' }
                      onClick={() => setActiveSection('instructor')}
                      >Instructors</div>
                           <div className={activeSection === 'Enterprise Gallery'? 'active' : '' }
                      onClick={() => setActiveSection('Enterprise Gallery')}
                      > Gallery</div>
                      <div className={activeSection === 'branches'? 'active' : '' }
                      onClick={() => setActiveSection('branches')}
                      > branches</div>
             </div>
          
     
             <div style={{display:'flex',justifyContent:'center',alignItems:'center',width :'100%'}}>  
               {renderSection()}
             </div>
  
    </div>
  )
}

export default EnterpriseHome