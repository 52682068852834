import React, { useEffect , useState , useRef , useCallback } from 'react';
import { motion } from 'framer-motion';
import './EnterpriseCreateCourse.css';
import CourseLevelSelector from '../../InstructorAsUser/InstructorDashboard/CourseLevelSelector';
import CityDropdown from '../../InstructorAsUser/InstructorDashboard/City';
import CategoryDropdown from '../../InstructorAsUser/InstructorDashboard/Category';
import TimeSessionSelector from '../../InstructorAsUser/InstructorDashboard/TimeSessionSelector';
import UploadIcon from '../../../assets/images/lmg_upload.svg'
import FAQsComponent from '../../../component/FAQSComponent/FAQsComponent';
import ContentComponent from '../../../component/ContentComponent/ContentComponent';
import TimeComponent from '../../../component/TimeComponent/TimeCourse';
import MilestoneData from '../../../component/MilestoneDataComponent/MilestoneData';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../../config'
import InstructorDropdown from '../instructorEnterprise';
import { registerEnterpriseCourse } from '../../../store/enterpriseFeature/storecourseEnterpriseSlice';
import { useDispatch , useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CorrectStep from '../../../assets/images/Correct_Step.svg'
import LanguageSelect from "./LanguageSelect";
import BranchesDropDown from './BranchesDropDown';


const libraries = ["places"]; // لاستخدام المكتبة العادية بدون advanced markers
const steps = [
  'Step 1: Course Details',
  'Step 2: Course Content',
  'Step 3: Pricing',
  'Step 4: Review & Submit',
];

const EnterpriseCreateCourse = () => {
  const dispatch = useDispatch();
  const navigate =  useNavigate()
  const location = useLocation();
  const branchLength = location.state?.branchLength;
  console.log("Branch Length:", branchLength);
  const { isLoading, success, error } = useSelector((state) => state.enterpriseCourse); // Access state

    // react redux
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedInstructor , setSelectedInstructor] = useState('')
  const [selectedBranch , setSelectedBranch] = useState('')
  const [selectedFile, setSelectedFile] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [times, setTimes] = useState([]);

  const [languageIds, setLanguageIds] = useState([]);

  const fileInputRef = useRef(null);
   // select category by id 
  const [selectedCategory, setSelectedCategory] = useState('');
    // Time | Duration
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(30);
    const [sessions, setSessions] = useState(3);

    // map in section 4 
    // map and define lat and lng 
    const [markerPosition, setMarkerPosition] = useState({ lat: 30.033333, lng: 31.233334 });
    const mapRef = useRef(null);
    console.log("Test Map : ",markerPosition.lng)
    const onLoad = useCallback((map) => {
      mapRef.current = map;
 

  // إنشاء Marker عادي قابل للسحب
  const marker = new window.google.maps.Marker({
    position: markerPosition,
    map: mapRef.current,
    draggable: true,
  });

    // إضافة Listener لتحديث الإحداثيات بعد السحب
    marker.addListener('dragend', (event) => {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
      console.log("New Position:", newPosition );
    });
  }, [markerPosition]);


  // القيم الافتراضية للنموذج
  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    level_id :selectedLevel , 
    /*city_id: selectedCity,*/
    branch_id:selectedBranch,
    instructor_id : selectedInstructor,
    category_id: selectedCategory,
    type : '',
   /* content:'',*/
    price: '',
    seats_count: '',
    has_certicate: '',
    course_hours: '', // initialize with hours
    session_count:'' ,// initialize with sessions
    lat: markerPosition.lat,
    lng: markerPosition.lng,
    languages_id: [], // Initialize as empty array
  });

 const [errors, setErrors] = useState({});

 const validateFields = () => {
  const newErrors = {};
  if (!formValues.title) newErrors.title = 'Title is required';
  if (!formValues.description) newErrors.description = 'Description is required';
  //if (!formValues.type) newErrors.type = 'Course type is required';
 // if (!formValues.promoVideoLink) newErrors.promoVideoLink = 'Promo video link is required';
 /* if (!selectedCity) newErrors.city_id = 'City is required';*/
  if (!selectedLevel) newErrors.level_id = 'Course level is required';
  if (!selectedCategory) newErrors.category_id = 'Category is required';
  if (!selectedInstructor) newErrors.instructor_id = 'Instructor is required';

  if (currentStep === 1) {  
    if (!formValues.price) newErrors.price = 'price is required';
    //if (!formValues.seats_count) newErrors.seats_count = 'price is number of seats';
    
  }

  // التحقق من الحقول الخاصة بالخطوة 2 فقط
  if (currentStep === 2) {  
    if (!faqs.length) newErrors.faqs = 'At least one FAQ is required';
    if (!contentData.length) newErrors.contentData = 'At least one content item is required';
    if (!milestones.length) newErrors.milestones = 'At least one milestone is required';
    if (!times.length) newErrors.times = 'At least one time option is required';
  }
 /* if (!selectedBranch) newErrors.branch_id = 'Branch is required' ;*/
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

   // Update formValues with selected languages
   const handleLanguagesChange = (selectedLanguages) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      languages_id: selectedLanguages, // Update languages_id array
    }));
  };
   const handleCityChange = (cityId) => {
     setSelectedCity(cityId);
    };

    const handlebranchChange = (branchId) => {
         setSelectedBranch(branchId);
     };

      // handle instructor 
    const handleInstructorChange = (instructorId) => {
      setSelectedInstructor(instructorId);
      console.log(`Selected Instructor ID: ${instructorId}`);
      // Perform actions, such as assigning a course to the instructor
    };

    console.log('test category ID : ' , selectedCategory)
    const handleCategoryChange = (categoryId) => {
     setSelectedCategory(categoryId);
   };


   const handleLevelChange = (levelId) => {
    setSelectedLevel(levelId);

  };
  // تحديث الحقول عند التغيير
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  console.log('formValues.type',formValues.type ,formValues , )

    // Synchronize city_id with selectedCity
    useEffect(() => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        city_id: selectedCity,
        level_id :selectedLevel,
        course_hours : hours ,
        session_count : sessions ,
        instructor_id : selectedInstructor,
        branch_id : selectedBranch , 
      }));
    }, [selectedCity , selectedLevel , hours ,sessions , selectedBranch]);

    // Synchronize category_id with selectedCategory
    useEffect(() => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        category_id: selectedCategory,
      }));
    }, [selectedCategory]);


    const handleFileInputChange = (e) => {
      const file = e.target.files[0];
      const fileType = file?.type;
    
      // تحقق من أن الملف هو صورة
      if (file && fileType.startsWith('image/')) {
        setSelectedFile(file);
        setImagePreviewUrl(URL.createObjectURL(file)); // Create a URL for the selected image
      } else {
        alert('The image must be an image file.');
        setSelectedFile(null);
        setImagePreviewUrl(null);
      }
    };

      const handleImageClick = () => fileInputRef.current.click();

    // الجزء الخاص بالخطوات 
  const [currentStep, setCurrentStep] = useState(0);
  const nextStep = () => {
    if (!validateFields()) {
      return; // Prevent moving to the next step if validation fails
    }
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };
  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

   // انهاء الجزء الخاص بالخطوات 

   const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the course data object
    const courseData = {
      ...formValues,
      image: selectedFile,
      faqs: faqs,
      contentData: contentData,
      milestoneData: milestones,
      times: times,
    };

    try {
      // Dispatch the action and await the result
      await dispatch(registerEnterpriseCourse(courseData)).unwrap();

      // If successful, display success message
      
      navigate('/enterprise/home' , { state: { activeSection: 'course' } });
    } catch (error) {
      // Handle error
      console.error("Failed to register course:", error);
    }
  };

  return (
    <div className="enterprise-create-course">
      {/*  <h1>{steps[currentStep]}</h1> */}
      <motion.div className="step-indicator">
  {steps.map((step, index) => (
    <div className="step-container" key={index}>
      <motion.div
        className={`step ${index <= currentStep ? 'active' : ''}`}
        initial={{ scale: 0.8, opacity: 0 }} // تأثير البدء
        animate={{ 
          scale: index <= currentStep ? 1.2 : 1, 
          opacity: 1,
          rotate: index <= currentStep ? 0 : -10 // دوران بسيط
        }} // تأثير الحركة عند التفعيل
        exit={{ scale: 0.8, opacity: 0 }} // تأثير الخروج
        transition={{ 
          duration: 0.3, 
          type: "spring", 
          stiffness: 500, // صلابة الحركة
          damping: 30 // تثبيط الحركة
        }} 
      >
        {index < currentStep ?  <img  src={CorrectStep} alt='correctstep'/>  : index + 1} {/* علامة الصح أو الرقم */}
      </motion.div>
      {index < steps.length - 1 && <div className="line"></div>} {/* خط بين الخطوات */}
    </div>
  ))}
</motion.div>

      <form onSubmit={currentStep === steps.length - 1 ? handleSubmit : (e) => e.preventDefault()}>
        <div className="form-content">
          {currentStep === 0 &&
           // Step one in create Enterprise Course  
           <div className='Enterprise_createCourse_Step1'>

           {/***************** Course Right Section *******************/}
           <div className="EnterpriseCreateCourse_form_Right_Section">
             <div className="EnterpriseCreateCourse_form_Section_title">
               Course Title <span className='Mandatory_star'>*</span>
             </div>
             <input
               type="text"
               name="title"
               placeholder='Course name "Devops"'
               value={formValues.title}
               onChange={handleInputChange}
               className="InstructorCreateCourse_form_Section_input"
               required // Mark as required for browser validation
             />
                {errors.title && <div className="error-message-enterpriseform">{errors.title}</div>} 


      <div  style={{display:'flex', flexDirection:'column', gap:'10px'}}>
             {/*      
          <div className="EnterpriseCreateCourse_form_Section_title">City <span className='Mandatory_star'>*</span></div>
             <CityDropdown
               selectedCity={selectedCity}
               onCityChange={handleCityChange}
               language="en" // or 'ar' based on preference
               required // Mark as required for browser validation
             />
             {errors.city_id && <div className="error-message-enterpriseform">{errors.city_id}</div>}
             */} 
        <div>
        
        <div className="EnterpriseCreateCourse_form_Section_title">Course Category <span className='Mandatory_star'>*</span></div>
        <CategoryDropdown
          selectedCategory={selectedCategory}
          onCategoryChange={handleCategoryChange}
        />
         {errors.category_id && <div className="error-message-enterpriseform">{errors.category_id}</div>}
      </div>

      <div >
        <div className="EnterpriseCreateCourse_form_Section_title">Course Level <span className='Mandatory_star'>*</span></div>
          <CourseLevelSelector selectedLevel={selectedLevel} onLevelChange={handleLevelChange} />
          {errors.level_id && <div className="error-message-enterpriseform">{errors.level_id}</div>}
        </div> 


        <div>
        <div className="EnterpriseCreateCourse_form_Section_title">Type Of Course <span className='Mandatory_star'>*</span></div>
        <select
           name="type"
           value={formValues.type}
           onChange={handleInputChange}
           required // Mark as required for browser validation
           className="city-select"
           style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
        >
             <option value="" disabled>
            Select type course
         </option>
          <option value="1">Offline</option>
          <option value="0">Online</option>
        </select>
        {errors.type && <div className="error-message-enterpriseform">{errors.type}</div>}
      </div>
         {/* branch */}
        {/* Branch Dropdown (Only for Offline) */}
      {formValues.type === "1" && (
        <div>
          
          {branchLength === 0 ?  'The Offline Will be in Enterprise Address'  :
             <>  
            <div className="EnterpriseCreateCourse_form_Section_title">
            Select Branch <span className="Mandatory_star">*</span>
          </div>
          <BranchesDropDown
          selectedBranch={selectedBranch}
          onBranchChange={handlebranchChange}
        />
           {errors.branch && (
            <div className="error-message-enterpriseform">{errors.branch}</div>
          )}
        </> 
        }
        
       
        </div>
      )}

      </div>

        
               
           </div>
         
           {/***************** Vertical Line *******************/}
           <div className="vertical-line"></div>
         
           {/***************** Course Left Section *******************/}
           <div className="EnterpriseCreateCourse_form_left_Section">

            <div>       <div className="EnterpriseCreateCourse_form_Section_title">
               Course Description <span className='Mandatory_star'>*</span>
             </div>
             <textarea
               rows="2"
               name="description"
               placeholder='Write a description about the course'
               value={formValues.description}
               onChange={handleInputChange}
               className="InstructorCreateCourse_form_Section_input"
               required // Mark as required for browser validation
             />  
              {errors.description && <div className="error-message-enterpriseform">{errors.description}</div>}
              </div>
        

        <div className="s">
        <div className="EnterpriseCreateCourse_form_Section_title">Promo Video Link </div>
        <textarea
          rows="2"
          name="promoVideoLink"
          value={formValues.promoVideoLink}
          onChange={handleInputChange}
          placeholder='https://www.youtube.com/watch?v=hWZRy3nKsJQ'
          className="InstructorCreateCourse_form_Section_input"
          required // Mark as required for browser validation
        />
         {errors.promoVideoLink && <div className="error-message-enterpriseform">{errors.promoVideoLink}</div>}
      </div>

        {/* 
      <div className="s">
        <div className="EnterpriseCreateCourse_form_Section_title">Content <span className='Mandatory_star'>*</span></div>
        <textarea
          rows="1"
          name="content"
          placeholder='please write a one word'
          value={formValues.content}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
          required // Mark as required for browser validation
        />
          {errors.content && <div className="error-message-enterpriseform">{errors.content}</div>}
      </div>
    */}
      <div>
      <h2 className="EnterpriseCreateCourse_form_Section_title">Course Instructor <span className='Mandatory_star'>*</span></h2>
      <InstructorDropdown
        selectedInstructor={selectedInstructor}
        onInstructorChange={handleInstructorChange}
        language="en" // or "ar" for Arabic
      />
      {errors.instructor_id && <div className="error-message-enterpriseform">{errors.instructor_id}</div>}
    </div>


           </div>
         
  

         </div>
         
          }
            {currentStep === 1 && <div className='Enterprise_createCourse_Step1'>
               {/*  Right Section  */} 
                  <div className="EnterpriseCreateCourse_form_Right_Section">

              <div className="s">
                      <div className="EnterpriseCreateCourse_form_Section_title">
                        Price <span className="Mandatory_star">*</span>
                      </div>
                      <input
                        type="number"
                        name="price"
                        value={formValues.price}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0) handleInputChange(e); // Allow only non-negative numbers
                        }}
                        className="InstructorCreateCourse_form_Section_input"
                        required // Mark as required for browser validation
                        min="0" // Prevents negative values using HTML5 validation
                  />
                    {errors.price && <div className="error-message-enterpriseform">{errors.price}</div>}
             </div>

             <div className="s">
                        <div className="EnterpriseCreateCourse_form_Section_title">
                          Seats Count 
                        </div>
                         <input
                          type="number"
                          name="seats_count"
                          value={formValues.seats_count}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0) handleInputChange(e); // Allow only non-negative numbers
                          }}
                          className="InstructorCreateCourse_form_Section_input"
                        
                          min="0" // Prevents negative values using HTML5 validation
                        />
              </div>

              <div className="s">
                  <div className="EnterpriseCreateCourse_form_Section_title">Has Certificate </div>
                          <select
                            name="has_certicate"
                            value={formValues.has_certicate}
                            onChange={handleInputChange}
                            className="InstructorCreateCourse_form_Section_input"
                          >

                                                    <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                 </div>

                 <div className="EnterpriseCreateCourse_form_Section_title">Language </div>
                 <LanguageSelect  className="InstructorCreateCourse_form_Section_input" onSelectionChange={handleLanguagesChange} />

                 <div className="s">
                          <div className='s'>
                                <div className='EnterpriseCreateCourse_form_Section_title'>Duration <span className='Mandatory_star'>*</span></div> 
                                <TimeSessionSelector 
                                 setHours={setHours}
                                 hours={hours}
                                 minutes={minutes}
                                 setMinutes={setMinutes}
                                 sessions={sessions}
                                 setSessions={setSessions}
                                />

                        </div>
                 </div>


                  </div>
            
                    {/***************** Vertical Line *******************/}
                    <div className="vertical-line"></div>
            
                 <div className="EnterpriseCreateCourse_form_left_Section">
              <div className="InstructorCreateCourse_form_Section">
                             <div className="InstructorCreateCourse_form_Section_title">Upload Cover Photo <span className='Mandatory_star'>*</span></div>
                             <div onClick={handleImageClick} style={{ cursor: 'pointer', position: 'relative', height: '300px', width: '100%', overflow: 'hidden' }}>
                               <img src={UploadIcon} alt="Upload Icon" style={{ width: '50px', height: '50px', position: 'absolute', top: '10px', left: '10px' }} />
                               {selectedFile && <p style={{ position: 'absolute', top: '70px', left: '10px', color: '#000' }}>{selectedFile.name}</p>}
                               <input
                                 type="file"
                                 ref={fileInputRef}
                                 style={{ display: 'none' }}
                                 onChange={handleFileInputChange}
                               />
                               {imagePreviewUrl && ( // Show the selected image if available
                                 <img
                                   src={imagePreviewUrl}
                                   alt="Selected Cover"
                                   style={{ width: '350px', height: '350px', objectFit: 'cover' }} // Cover the entire container
                                 />
                               )}
                             </div>
               </div>              


                 </div>
            
            </div>}


          {currentStep === 2 &&
          <div className='Enterprise_createCourse_Step1'>
             <div className="EnterpriseCreateCourse_form_Right_Section">
                          {/*  Faqs component */}
                                  <div className="s" style={{display:'flex', flexDirection:'column', gap:'25px'}}>
                                     {/* Render the FAQsComponent and pass faqs & setFaqs */}
                                     <FAQsComponent faqs={faqs} setFaqs={setFaqs} />
                                     {errors.faqs && <p className="error-message-enterpriseform">{errors.faqs}</p>}
                                     <ContentComponent contentData={contentData} setContentData={setContentData} />
                                     {errors.contentData && <p className="error-message-enterpriseform">{errors.contentData}</p>}
                                    </div>
             </div>
            {/***************** Vertical Line *******************/}
                    <div className="vertical-line"></div>

                    <div className="EnterpriseCreateCourse_form_left_Section">
                                                    
                                   <MilestoneData milestones={milestones} setMilestones={setMilestones} />
                                   {errors.milestones && <p className="error-message-enterpriseform">{errors.milestones}</p>}
                                        <div style={{marginTop:'25px' }} >
                                           <p>These dates represent the start of the course. You can select up to 3 dates to begin the semester <span className='Mandatory_star'>*</span></p>
                                           <TimeComponent times={times} setTimes={setTimes} />
                                           {errors.milestones && <p className="error-message-enterpriseform">{errors.milestones}</p>}
                                          {/* <pre>{JSON.stringify(times, null, 2)}</pre> */} 
                                        </div>
                    </div>

          </div>}


          {currentStep === 3 && 
           <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
            
                 
            {formValues.type === "1" && ( <>
            
              <div className="InstructorCreateCourse_form_Section">
                     <div className="InstructorCreateCourse_form_Section_title">Select your physical Location if course Offline</div>
                     <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
                     <GoogleMap
                      mapContainerStyle={{ height: "400px", width: "100%" }}
                      center={markerPosition}
                      zoom={10}
                      onLoad={onLoad}
                     />
                     </LoadScript>
                </div> 
            
            
            
            </> )}
            
          
            
            
            
            </div>}
        </div>
        <div className="buttons">
          {currentStep > 0 && <button type="button" onClick={prevStep} className='Enterprise_Previous_Button'>Previous</button>}
          {currentStep < steps.length -1 ? (
            <button type="button" onClick={(e) => {
              e.preventDefault(); // منع عملية الـ submit
              nextStep();
            }} className='Enterprise_Next_Button'>Next</button>
          ) : (
            <button type="submit"  className='Enterprise_Next_Button' >{isLoading ? 'Submitting...' : 'Submit Course'}</button>
          )}
        </div>
      </form>
      {success && (
  <div>
    <p>Status: {success.status}</p>
    <p>{success.status}</p>
    
  </div>
)}
    {error ?     <div className="error-message-enterpriseform">{error.msg}</div> : ' '}
    </div>
  );
};

export default EnterpriseCreateCourse;
