import React, { useState, useEffect } from 'react';
import Logo from '../../../assets/images/lndexlogo_svg.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../../../component/Bootstrap/Error_Message';
import Footer from '../../../component/Footer/Footer';
import { forgetEnterprisePassword } from '../../../store/enterpriseFeature/enterpriseForgetPasswordSlice';

const ForgetEnterprise = () => {
  const [email, setEmail] = useState('');
  const [localError, setLocalError] = useState(''); // State for local error handling

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Accessing the slice state
  const { loading, error, success, msg } = useSelector(
    (state) => state.enterpriseForgetPassword
  );
   
 console.log(error)

  const handleSubmit = () => {
    if (!email.trim()) {
      setLocalError('Please enter your email'); // Set local error message
      return;
    }

    // Clear local error if email is valid
    setLocalError('');

    dispatch(forgetEnterprisePassword({ email }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  
  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        console.log('dd', msg);
        navigate('/otp-forget-enterprise'); // Adjust the path as needed
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [success, navigate, msg]);

  return (
    <>
      <div
        className="Registrition_Body_forget"
        style={{ marginTop: '50px' }}
        onKeyDown={handleKeyDown}
      >
        <div className="Signup_body">
          <div className="Signup_body_Form">
            <div className="Signup_body_Form_Sec1">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="Signup_body_Form_Sec2">Forgot password</div>
            <div className="Signup_body_Form_Sec3">
              <hr />
            </div>
            <div className="Signup_body_input_container">
              <div className="Signup_body_Form_Sec4">
                <div>Email</div>
                <div className="Signup_body_input">
                  <input
                    className="Sign_input"
                    type="email"
                    placeholder="ayamustafa@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="Registration_body_Form_Sec5">
              <button
                className="Signup_body_Form_Sec5_button"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send'}
              </button>
            </div>
                {/* Display error message from the slice */}
                {error && (
                <div style={{ color: 'red', marginTop: '10px', fontWeight: 'bold', zIndex: '100' }}>
                  {error}
                </div>
              )}
            <div className="Signup_body_Form_Sec3">
              <hr />
              {/* Display local error message */}
              {localError && (
                <div style={{ color: 'red', marginTop: '10px', fontWeight: 'bold', zIndex: '100' }}>
                  {localError}
                </div>
              )}
          
              {/* Display success message */}
              {msg && success && (
                <div style={{ color: 'green', marginTop: '10px', fontWeight: 'bold', zIndex: '100' }}>
                  {msg}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgetEnterprise;