import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { URL } from "../../config";
//  ✅ 1. تعريف الـ Thunk لتحديث بيانات الفرع
export const updateBranch = createAsyncThunk(
  "branches/updateBranch",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const token = Cookies.get("entoken"); // احضار التوكن من الكوكيز
      const response = await axios.post(`${URL}/enterprise/branches/update/${id}`, formData, {
        headers: {
         token
        },
      });

      return response.data; // بيانات الفرع بعد التحديث
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Error updating branch");
    }
  }
);

// ✅ 2. إنشاء Slice خاص بتحديث الفرع
const updateBranchSlice = createSlice({
  name: "updateBranch",
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    resetUpdateState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBranch.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateBranch.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(updateBranch.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

// ✅ 3. تصدير الـ Reducer & Actions
export const { resetUpdateState } = updateBranchSlice.actions;
export default updateBranchSlice.reducer;
