import React, { useState } from 'react';
import purchasesicon from '../../assets/images/purchasesicon.svg';
import ArrowDrop from '../../assets/images/review/StudentArrow.svg';
import Footer from '../Footer/Footer';
import { useLocation } from 'react-router-dom';

const InstructorPurchases = () => {
  const location = useLocation();
  const students = location.state?.course?.students; // Get the students data

  const [openStudent, setOpenStudent] = useState(null); // Track which student info is open

  const toggleStudentInfo = (id) => {
    setOpenStudent((prev) => (prev === id ? null : id)); // Toggle info visibility
  };

  if (!students || students.length === 0) {
    return <div>No Student data available</div>;
  }

  return (
    <>
      <div className='coursepurchases_cointainer'>
        <img style={{ width: '60px' }} src={purchasesicon} alt='purchasesicon' />
        <div className='coursepurchases_Sec1'>
          <span style={{ color: '#3F52FC' }}>{students.length}</span>: purchases
        </div>
      </div>

      <div className='students-list-container'>
        {students.map((student) => (
          <div key={student.id} className='student'>
            {/* Header - Clickable to Show/Hide Info */}
            <div className='student-header' onClick={() => toggleStudentInfo(student.id)} style={{ cursor: 'pointer' }}>
              <div className='student-header-left'>
                <img src={student.image} alt={student.first_name} className='student-image' />
                <h4>{student.first_name} {student.last_name}</h4>
              </div>
              {/* Dropdown Arrow (Rotate on Click) */}
              <div>
                <img
                  src={ArrowDrop}
                  alt='ArrowDrop'
                  className={`arrow-icon ${openStudent === student.id ? 'rotate' : ''}`}
                />
              </div>
            </div>

            {/* Student Info - Show only if openStudent matches the student's ID */}
            {openStudent === student.id && (
              <div className='student-info'>
                <h4>{student.first_name} {student.last_name}</h4>
                <p>Email: {student.email}</p>
              </div>
            )}
            
            <div className='student_line'></div>
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default InstructorPurchases;
