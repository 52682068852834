import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCourses } from '../../store/userFeature/GetUserCourseSlice'; // Import the action
import './CourseDetails.css';
import ArrowLeftGuide from '../../assets/images/ArrowLeft.svg';
import { Link } from 'react-router-dom';
import coursesaved from '../../assets/images/Course/Saved.svg';
import Description from '../../component/CourseDeatils/Description';
import Content from '../../component/CourseDeatils/Content';
import CourseLocation from '../../component/CourseDeatils/CourseLocation';
import Review from '../../component/CourseDeatils/Review';
import datestart from '../../assets/images/coursecard/blank-calendar.svg';
import ShopingCart from '../../assets/images/coursecard/shopping-cart-details.svg';
import purchased from '../../assets/images/review/purchasedIcon.svg'
import beginner from '../../assets/images/coursecard/beginner.svg';
import Line from '../../assets/images/coursecard/LineCourseCard.svg';
import Hours from '../../assets/images/coursecard/clock.svg';
import Chairs from '../../assets/images/coursecard/sets.svg';
import Certificate from '../../assets/images/coursecard/certificate.svg';
import Arabic from '../../assets/images/coursecard/volume.svg';
import Star from '../../assets/images/coursecard/Star.svg';
import YouTubePlayer from '../../component/YoutubePlayer/YoutubePlayer';
import Loading from '../../component/Loading/Loading';
import Cookies from 'js-cookie';
import { addCourseToCart } from '../../store/userFeature/addCourseToCartSlice';
import { motion } from 'framer-motion';
import UserNavbar from '../../component/UserNavbar/UserNavbar';
import logo from '../../assets/images/lndexlogo_svg.svg'
import Footer from '../../component/Footer/Footer';
import { followInstructor, unfollowInstructor } from '../../store/userFeature/userFollowInstructorSlice';


const CourseDetailsLoggedIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const courses = useSelector((state) => state.allCourses?.courses || []);
  const [popupMessage, setPopupMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const token = Cookies.get('authToken');

  const courseId = parseInt(id, 10);
  const course = courses.find((course) => course.id === courseId);

  useEffect(() => {
    if (token) {
      dispatch(fetchAllCourses(token)); // Fetch user-specific courses when the component mounts
    }
  }, [token, dispatch]);

  const handleAddToCartClick = (courseId) => {
    setIsLoading(true); // Set loading state to true
    console.log(courseId)
    dispatch(addCourseToCart({ courseId, isEnterprise: false }))
      .unwrap()
      .then(() => {
        console.log('Course added to cart successfully!');
        navigate('/UserCart'); // Redirect to the UserCart page
      })
      .catch((error) => {
        console.error('Failed to add course to cart:', error);
        setPopupMessage(error.message || 'Failed to add course to cart.');
        setShowPopup(true);
      })
      .finally(() => {
        setIsLoading(false); // Set loading state to false
      });
  };

   // Framer Motion Spinner Component
   const Spinner = () => (
    <motion.div
      style={{
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        border: '3px solid #f3f3f3',
        borderTop: '3px solid #3498db',
      }}
      animate={{ rotate: 360 }} // Rotate animation
      transition={{ duration: 1, repeat: Infinity, ease: 'linear' }} // Infinite loop
    />
  );

  const handleFollowClick = () => {
    console.log('Following instructor...');
    // Add follow logic here
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const [activeSection, setActiveSection] = useState('Description');
  const renderSection = () => {
    switch (activeSection) {
      case 'Description':
        return <Description course={course} />;
      case 'Content':
        return <Content course={course.content_data} />;
      case 'Course Location':
        return <CourseLocation course={course}/>;
      case 'Review':
        const isEnterprise = 0 ; 
        return <Review courseId={courseId} isEnterprise={isEnterprise} />;
      default:
        return null;
    }
  };

  if (!course) {
    return <Loading />; // Show loading spinner if course data is not yet available
  }

  return (
    <div>
          <div style={{display:'flex', justifyContent:'space-around',alignItems:'center',paddingBottom:'50px'}}>
          <div><Link to='/home'><img src={logo} alt='logo' /></Link> </div>
          <UserNavbar />
        </div>
      {/* Course details container */}
      <div className='CourseEnterPriseDetails_Container'>
        {/* Section 2 */}
        <div className='CourseDetails_Sec2'>
          {/* Part 2 */}
          <div className='CourseDetails_Sec2_Part2'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='CourseDetails_Sec1'>
                <Link to='/home' className='link'>
                  <div>Home</div>
                </Link>
                <div>
                  <img src={ArrowLeftGuide} alt='arrow' />
                </div>
                <div>Course Details</div>
              </div>
              <span className='CourseDetails_Sec2_Part1'>{course?.title}</span>
              {/* Pass the video URL */}
              {course?.promo_video_link ? (
                <YouTubePlayer videoUrl={course?.promo_video_link} />
              ) : (
                <img
                  src={course?.image || 'default-placeholder.png'}
                  alt='course'
                  style={{ width: '650px', maxHeight: '700px' }}
                />
              )}
              {/* Save course button 
              <div className='CourseDetails_Sec2_Part2_elementSaveCourse'>
                <div className='CourseDetails_Sec2_Part2_elementSaveCourse_item'>
                  <img src={coursesaved} alt='coursesaved' />
                  <div>Save Course</div>
                </div>
              </div>
              */}
              {/* Course content section */}
              <div className='CourseDetails_Sec2_Part2_CourseHeader' >
                <div className='CourseHeader'>
                  <div
                    className={activeSection === 'Description' ? 'active' : ''}
                    onClick={() => setActiveSection('Description')}
                  >
                    Description
                  </div>
                  <div
                    className={activeSection === 'Content' ? 'active' : ''}
                    onClick={() => setActiveSection('Content')}
                  >
                    Content
                  </div>
                  <div
                    className={activeSection === 'Course Location' ? 'active' : ''}
                    onClick={() => setActiveSection('Course Location')}
                  >
                    Course Location
                  </div>
                  <div
                    className={activeSection === 'Review' ? 'active' : ''}
                    onClick={() => setActiveSection('Review')}
                  >
                    Review
                  </div>
                </div>
                <div className='CourseHeader_Body'>{renderSection()}</div>
              </div>
            </div>
            {/* Cart element */}
            <div className='CourseDetails_Sec2_Part2_CartElement'>
              <div className='CourseDetails_Sec2_Part2_CartElement_part1'>
                <div className='CartElement_Date'>
                  <img src={datestart} alt='datestart' />
                  Start Date
                </div>
                <div className='date-picker'>
                  {course?.times.map((time, index) => (
                    <div key={index} className='date-option'>
                      <span className='date-text'>{time.date}</span>
                    </div>
                  ))}
                </div>
                {/* Price */}
                <div className='CartElement_ThePrice'>
                  <div>THE PRICE:</div>
                  <span>{course?.price} EGP</span>
                </div>
                {/* Add to cart button */}
                {course?.is_buy === 1  ? 
                <div style={{display:'flex', gap:'8px'}}>
                   <img src={purchased} alt='purchased' />
                   <div className='purchased'>purchased</div>
                </div>
                :
                <div className='CartElement_button_C' onClick={() => handleAddToCartClick(course.id)}>
                 <div className='CartElement_button_Cart'>
                   <img src={ShopingCart} alt='ShopingCart' />
                   {isLoading ? <Spinner /> : 'Add to Cart'}
                 </div>
                </div>
                }
             
                {/* Divider */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img style={{ width: '300px' }} src={Line} alt='Line' />
                </div>
                {/* Course details */}
                <div className='CartElement_Details'>
                  <div className='CartElement_Details_item'>
                    <img src={beginner} alt='beginner' />
                    {course?.level?.name}
                  </div>
                  <div className='CartElement_Details_item'>
                    <img src={Hours} alt='beginner' />
                    {course?.course_hours} hours ({course?.session_count} sessions)
                  </div>
                  <div className='CartElement_Details_item'>
                    <img src={Chairs} alt='Chairs' />
                    {course?.seats_count} seats left
                  </div>
                  <div className='CartElement_Details_item'>
                    <img src={Certificate} alt='Certificate' />
                    {course?.has_certicate === 1 ? 'Certificate' : 'No Certificate'}
                  </div>
                  <div className='CartElement_Details_item'>
                    <img src={Arabic} alt='Arabic' />
                    {course?.lang ? 'Arabic' : 'English'}
                  </div>
                  <div className='CartElement_Details_item'>
                    <img src={Star} alt='Star' />
                    {course?.rate} 95% (234)
                  </div>
                </div>
              </div>
              {/* Instructor card */}
              <div className='Insructor_CardElement'>
                <div className='Insructor_CardElement_Title'>Instructor</div>
                <div className='Insructor_CardElement_information'>
                  <div>
                    <img
                      style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      src={course?.instructor?.profile_image}
                      alt='InstructorIcon'
                    />
                  </div>
                  <div className='Insructor_CardElement_information_Part2'>
                    <div className='Insructor_CardElement_information_Part2_Name'>
                      {course?.instructor?.name}
                    </div>
                    <div className='Insructor_CardElement_information_Part2_major'>
                      {course?.instructor?.career}
                    </div>
                    <div className='Insructor_CardElement_information_Part2_Date'>
                      {course?.instructor.created_at}
                    </div>
                    <div className='Insructor_CardElement_information_Part2_star'>
                      <img style={{ width: '15px', height: '15px' }} src={Star} alt='Star' /> 95% (234)
                    </div>
                  </div>
                </div>
                {/* Follow button */}
             <div style={{display:'flex', justifyContent:'center',width:'100%'}}>

                 <div className='Instructor_Sec5' >
                {course?.instructor?.is_follow === 1 ? (
                <button
                onClick={handleFollowClick}
                  style={{backgroundColor:'#18172F', color:'#fff'}}
                >
                   unfollow
                </button>
              ) : (
                <button
                onClick={handleFollowClick}
                >
                  follow
                </button>
              )}
                </div>
           </div>

         
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Popup for login/signup message */}
      {showPopup && (
        <div className='popup'>
          <div className='popup-content'>
            <div>
              <span className='close' onClick={closePopup}>
                &times;
              </span>
            </div>
            <div>
              <p className='Popup_Message'>{popupMessage}</p>
            </div>
            {!token && (
              <div className='popup_button_container'>
                <Link className='link' to='/Login'>
                  <button className='popup_button_Login'>Login</button>
                </Link>
                <Link className='link' to='/Signup'>
                  <button className='popup_button_Signup'>Sign Up</button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
       <Footer/>
    </div>
  );
};

export default CourseDetailsLoggedIn;