import React , {useEffect} from 'react'
import './sidebar.css'
import  logo  from '../../assets/images/lndexlogo_svg.svg'
import Home from '../../assets/images/Subtract.png'
import Cart from '../../assets/images/shopping-cart.png'
import mycourse from '../../assets/images/Courses.png'
import saved from '../../assets/images/Saved.png'
import Blog from '../../assets/images/blogSvg.svg'
import careerpath from '../../assets/images/careerpath.png'
import { fetchUserShowCart } from '../../store/userFeature/showUserCartSlice'
import { useDispatch , useSelector } from 'react-redux'
import { Link , useLocation } from 'react-router-dom' // using uselocation from react-router 
const Sidebar = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const currentPath = location.pathname; // المسار الحالي 
   const isActive = (path) => currentPath === path;    // To Check about items in Sidebar and the current path 


const { courses, status  } = useSelector((state) => state.userShowCart);
  // استدعاء الـ thunk عند تحميل الـ component
  useEffect(() => {
    dispatch(fetchUserShowCart());
  }, [dispatch]);
  
  const item_number = courses?.total_quantity || 0; // إذا كانت undefined، القيمة الافتراضية 0

  return (
    <div>
    <div className='Sidebar_container'>
      {/* this wrap logo and list with gap 100 between this  */}
         <div className='Sidebar_Sec1'>
           <div> <Link to='/home' className='link'><img src={logo} alt='lndexlogo'></img></Link></div>
           <div className='Sidebar_Part1_List'>
              <ul>
              <Link to='/home' className='link'>   <li className={isActive('/home') ? 'active' : ''}><img src={Home} alt='home_icon'></img>home</li></Link>
              <Link to='/UserCart' className='link'>  <li className={isActive('/UserCart') ? 'active' : ''} style={{display:'flex',gap:'5vw'}}><div><img src={Cart} alt='cart_icon'></img> cart</div><span className='cart_item'>{item_number}</span></li></Link>
              <Link to='/MyCourses'  className='link'>  <li className={isActive('/MyCourses') ? 'active' : ''}><img src={mycourse} alt='mycourse'></img>my course</li></Link> 
               <Link to='/User-saved-course'  className='link'><li className={isActive('/User-saved-course') ? 'active' : ''}><img src={saved} alt='saved'></img>saved course</li></Link> 
               <Link to='/UserBlog' className='link'> <li className={isActive('/UserBlog') ? 'active' : ''}><img src={Blog} alt='cart_icon'></img>Blog</li></Link>
                {/* <li><img src={careerpath} alt='careerpath'></img>Career paths</li> */}
              </ul>
           </div>
         </div>
         <div></div>
         <div className='Sidebar_Footer'>@Copyright Lndex All rights Reserved</div>
    </div>
    </div>
  )
}

export default Sidebar
