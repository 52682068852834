import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/images/instructors/add.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBranches } from "../../../store/branches/getBranchesSlice";
import { deleteBranch } from "../../../store/branches/deletebranchSlice";
import phoneIcon from '../../../assets/images/phoneIcon.svg'
import './Branches.css'



// إذا كان لديك مكون سبينر يمكنك استيراده، أو إنشاء مكون بسيط:
const Spinner = () => (
  <div style={{display:'flex', justifyContent:'center',width:'100%'}} className="spinner">
   
  </div>
);

const BranchesComponent = ({image}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // جلب البيانات من الـ Redux store
  const { branches, status, error } = useSelector((state) => state.displayBranches);
  const { status: deleteStatus } = useSelector((state) => state.deleteBranch);
  // عند تحميل المكون، نقوم باستدعاء الدالة لجلب بيانات الفروع
  useEffect(() => {
    dispatch(fetchAllBranches());
  }, [dispatch]);

  // دالة للتوجيه عند الضغط على زر تعديل الفرع
  const handleEdit = (id) => {
    navigate(`/enterprise/update-branch/${id}`);
  };

    // Handle Delete Branch
    const handleDelete = (id) => {
        dispatch(deleteBranch(id)).then(() => {
          dispatch(fetchAllBranches()); // Refresh branches list after deletion
        });
      }
   

  return (
    <div className="EnterpriseInstructor_Container">
      <div className="EnterpriseInstructor_LinkAlbum">
        <div
          className="col-lg-12 d-flex justify-content-end"
          style={{ position: "relative", top: "-50px" }}
        >
          <Link to="/enterprise/create-branch" className="link">
            <button className="InstructorHome_AddInstructor_Button">
              <img src={AddIcon} alt="AddTheIcon" className="me-1" />
              Add Branch
            </button>
          </Link>
        </div>
      </div>

      {/* عرض حالات التحميل والخطأ */}
      {status === "loading" && <Spinner />}
      {status === "failed" && (
        <div className="error-message" style={{ color: "red" }}>
          {error || "حدث خطأ أثناء جلب الفروع."}
        </div>
      )}

      {/* عرض بطاقات الفروع حالما يتم جلبها */}
      <div className="EnterpriseInstructor_Card_Container">
        {status === "succeeded" && branches.length === 0 ? (
          <div>No branches found.</div>
        ) : (
          branches.map((branch) => (
            <div className="EnterpriseInstructor_Card" key={branch.id}>
              <div className="EnterpriseInstructor_Card_content">
                <img
                  className="EnterpriseInstructor_Card_image"
                  src={image}
                  alt={branch.branch_name}
                  style={{backgroundImage:'cover'}}
                />
                <div className="Branch_Card_name">{branch.branch_name}</div>
                <div className="Branch_Card_phone"><img src={phoneIcon} alt="phoneIcon" />{branch.phone_branch}</div>
                <div className="branch_Card_info">
                <div className="Branch_Card_bio"><span>address : </span>{branch.address}</div>
               {/*  <div className="Branch_Card_bio"><span>country : </span>{branch.country?.name_en}</div>*/}
                <div className="Branch_Card_bio"><span>City : </span>{branch.city?.name}</div>
                <div className="Branch_Card_bio"><span>branch sub city : </span>{branch.sub_city?.name_en}</div>
                </div>
               
            <div className="Branch_Card_Container">
                  <button
                    className="EnterpriseInstructor_Card_button"
                    onClick={() => handleEdit(branch.id)}
                  >
                    Edit
                 </button>

                 <div className="EnterpriseInstructor_Card_button">
                   <button
                     className="branch_delete_button"
                     onClick={() => handleDelete(branch.id)} disabled={deleteStatus === "loading"}
                    >
                   {deleteStatus === "loading" ? "Deleting..." : "Delete"}
                   </button>
                 </div>
                      
            </div>                
           
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BranchesComponent;
