import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './Notification.css'; // Add your CSS file for custom styles
import { LineUtil } from 'leaflet';
import { Link } from 'react-router-dom';
import popupalert from '../../assets/images/popupAlert.svg'



const NewUserCourseNotification = ({ showNotification, onClose }) => {
  return (
    showNotification && (
      <>
      <div class="overlay"></div>
      <motion.div
        className="notification-container"
        initial={{ opacity: 0, x: '100%' }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ duration: 0.5 }}
      >
        <div className="notification-content">
          <div className='popupalert'>
              <img src={popupalert} alt='popupalert'/>
              <div>Instructors !</div>
          </div>
          <span className="notification-message">
          To add a new course, you must first assign an instructor. Your enterprise account currently has no registered instructors. Please add an instructor to proceed with course creation.
          </span>
          
            <div className='notification_buttons'>
            <button className='notification_buttons_NotNOW' onClick={onClose}>not now</button>
               <Link className='link' to='/enterprise/create-instructor'>
                 <button className='notification_buttons_Update'>Add Instructor </button> 
               </Link>  
            </div>
        
         
        </div>
      </motion.div>

      </>
    )
  );
};

export default NewUserCourseNotification;
