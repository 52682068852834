import React, { useEffect, useRef , useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLanguage } from "../../context/LanguageContext";
import './enterpriseInformationForm.css';
import RichTextEditor from "../../component/RichTextEditor/RichTextEditor";
import Saveicon from '../../assets/images/instructors/save.svg'
import { fetchEnterpriseProfile } from '../../store/enterpriseFeature/profileEnterpriseSlice';
import { updateEnterpriseProfile } from '../../store/enterpriseFeature/updateEnterpriseProfileSlice';
//import { resetEnterpriseProfileState } from '../../store/enterpriseFeature/updateEnterpriseProfileSlice';
//import { motion } from 'framer-motion';
//import { GoogleMap, LoadScript } from '@react-google-maps/api';
//import DOMPurify from 'dompurify';
//import  { REACT_APP_GOOGLE_MAPS_API_KEY }  from '../../config'
import { useNavigate } from 'react-router-dom';
//const libraries = ["places"]; // لاستخدام المكتبة العادية بدون advanced markers


const EnterpriseInformationForm = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const { profile } = useSelector((state) => state.enterpriseProfile);
   const Enterpricedata = profile?.data?.enterprise ;
  // console.log(Enterpricedata)
   {/* want when navigate to this route get all profile data   */}

   const { loading, success, error } = useSelector(
    (state) => state.updateEnterpriseProfile
  );



  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchEnterpriseProfile());
      } catch (error) {
        console.error("Error fetching enterprise profile:", error);
      }
    };
  
    fetchData();
  }, [dispatch]);


  const { language } = useLanguage();
  const [formData, setFormData] = useState({
    owner_name: "",
    name: "",
    contact_person : "",
    person_position :"",
    email: "",
    phone: "",
    website_link: "", // Added website field
    description: "",
    specialization: "",
    address: "",
    license_image: '',
    lat: '',
    lng: '',
  });



  useEffect(() => {
    if (Enterpricedata?.license_image) {
      setPreviewImage(Enterpricedata.license_image);
      setFormData((prevState) => ({
        ...prevState,
        license_image: Enterpricedata.license_image,
      }));
    }
  }, [Enterpricedata]);

  const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const checkIfAllFieldsArePopulated = (data) => {
    return (
      
      !!data.name?.trim() &&
      !!data.email?.trim() &&
      !!data.phone?.trim() &&
      !!data.website_link?.trim() && // تأكد أن الحقل موجود
      !!stripHTML(data.about_details)?.trim() &&
      !!data.specialization?.trim() &&
      !!data.address?.trim()
      
    );
  };

  useEffect(() => {
    // If profile data is available, update the form data
    if (Enterpricedata) {
      setFormData({
        owner_name: Enterpricedata.owner_name || "",
        contact_person : Enterpricedata.contact_person || "",
        person_position : Enterpricedata.person_position || "",
        name: Enterpricedata.name || "",
        email: Enterpricedata.email || "",
        phone: Enterpricedata.phone || "",
        website_link: Enterpricedata.website_link || "", // Ensure website field is populated
        description: Enterpricedata.about_details || "",
        specialization: Enterpricedata.specialization || "",
        address: Enterpricedata.address || "",
        license_image: Enterpricedata.license_image , // Clear any existing image before uploading a new one
        lat: Enterpricedata.lat,
        lng: Enterpricedata.lng,
      });

      if (Enterpricedata) {
        console.log("Enterpricedata available:", Enterpricedata);
    
        const allFieldsPopulated = checkIfAllFieldsArePopulated(Enterpricedata);
        console.log("3m meso", allFieldsPopulated);
    
        if (allFieldsPopulated) {
          localStorage.removeItem('userStatus');
        } else {
          localStorage.setItem('userStatus', 'newuser');
        }
      } else {
        console.log("Enterpricedata not available yet");
      }


    }
  }, [Enterpricedata]); // This hook will trigger when Enterpricedata changes
    
 // map and define lat and lng 
 const [markerPosition, setMarkerPosition] = useState({
  lat: Enterpricedata?.lat || 0,
  lng: Enterpricedata?.lng || 0,
});

  const [previewImage, setPreviewImage] = useState(null); // For previewing the image
  //const [previewImages, setPreviewImages] = useState([]); // For previewing additional images


 //console.log('TestImages' ,mainImage)

 const handleFileChange = (e) => {
  const file = e.target.files ? e.target.files[0] : null;

  if (file) {
    if (file.size > 2 * 1024 * 1024) {
      alert("File size must be less than 2MB.");
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      license_image: file,
    }));

    const objectUrl = URL.createObjectURL(file);
    setPreviewImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }
};


/*
const handleQuillChange = (value) => {
  const sanitizedValue = DOMPurify.sanitize(value);  // تنظيف النص
  setFormData((prevState) => ({
    ...prevState,
    description: sanitizedValue,
  }));
};
*/

const handleQuillChange = (value) => {
  // تحقق مما إذا كانت القيمة نصية
  if (typeof value === 'string') {
    setFormData((prevState) => ({
      ...prevState,
      description: value,
    }));
  } else {
    // إذا كانت القيمة كائنًا، قم بإظهار رسالة خطأ أو معالجة البيانات بطريقة مختلفة
    console.error('Data is not a valid string:', value);
  }
};

  const translations = {
    en: {
      title: "Enterprise Information Form",
      ownerFirstName: "Customer Support Agent Name",
      ownerspecialization: "Customer Support Agent specialization",
      enterpriseName: "Enterprise Name",
      email: "Email",
      phone: "Phone",
      website: "Website", // Added website translation
      about: "About",
      enterpriseTitle: "ENTERPRISE Specializations",
      address: "Enterprise detailed address",
      accreditation: "Accreditation",
      images: "Enterprise Images",
      selectImages: "Select up to 5 images",
      submit: "Save data",
      successMessage:'The Form Save successfully',
      placeholder: {
        ownerFirstName: "Enter owner's first name",
        ownerLastName: "Enter owner's last name",
        enterpriseName: "Enter enterprise name",
        email: "Enter email",
        phone: "+02135165666",
        website: "Enter enterprise website", // Added website placeholder
        about: "Write something about the enterprise",
        enterpriseTitle: "ENTERPRISE Specializations:",
        address: "Enter enterprise address",
      },
    },
    ar: {
      title: "نموذج معلومات الاكادميه",
      ownerFirstName: "الاسم الأول للمالك",
      ownerspecialization: "Customer Support Agent specialization",
      enterpriseName: "اسم المؤسسة",
      email: "البريد الإلكتروني",
      phone: "رقم الهاتف",
      website: "الموقع الإلكتروني", // Added website translation
      about: "نبذة عن المؤسسة",
      enterpriseTitle: "عنوان المؤسسة",
      address: "مجال المؤسسة",
      accreditation: "شهادة الاعتماد",
      images: "صور المؤسسة",
      selectImages: "اختر حتى 5 صور",
      submit: "حفظ البيانات",
      placeholder: {
        ownerFirstName: "أدخل الاسم الأول للمالك",
        ownerLastName: "أدخل اسم العائلة للمالك",
        enterpriseName: "أدخل اسم المؤسسة",
        email: "أدخل البريد الإلكتروني",
        phone: "+02135165666",
        website: "أدخل موقع المؤسسة", // Added website placeholder
        about: "اكتب نبذة عن المؤسسة",
        enterpriseTitle: "ENTERPRISE Specializations:",
        address: "أدخل عنوان المؤسسة",
      },
    },
  };

  const mapRef = useRef(null);
  console.log("Test Map : ",markerPosition.lng)
  const onLoad = useCallback((map) => {
    mapRef.current = map;
  // إنشاء Marker عادي قابل للسحب
  const marker = new window.google.maps.Marker({
    position: markerPosition,
    map: mapRef.current,
    draggable: true,
  });

    // إضافة Listener لتحديث الإحداثيات بعد السحب
    marker.addListener('dragend', (event) => {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
      console.log("New Position:", newPosition );
    });
  }, [markerPosition]);

  const texts = translations[language];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  console.log(formData.license_image);


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // تحقق من الأخطاء
    const errors = [];
    if (!String(formData.owner_name).trim()) errors.push("Owner name is required.");
    if (!String(formData.email).trim()) errors.push("Email is required.");
    if (!String(formData.phone).trim()) errors.push("Phone number is required.");
    if (!String(formData.website_link).trim()) errors.push("Website is required.");
    if (!String(formData.description).trim()) errors.push("Description is required.");
    if (!String(formData.specialization).trim()) errors.push("Specialization is required.");
    if (!String(formData.address).trim()) errors.push("Address is required.");
  
    // إزالة الحقول الفارغة
    const cleanedFormData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => String(value).trim())
    );
  
    // إنشاء FormData وإضافة البيانات النصية
    const formDataToSend = new FormData();
    for (const key in cleanedFormData) {
      formDataToSend.append(key, cleanedFormData[key]);
    }
  
    // إضافة الموقع المحدث
    formDataToSend.append("lat", String(markerPosition.lat));  
    formDataToSend.append("lng", String(markerPosition.lng));
  
    // إضافة الصورة إذا تم تحديدها وكانت من نوع File
    if (formData.license_image instanceof File) {
      formDataToSend.append("license_image", formData.license_image);
    }
  
    try {
      // إرسال البيانات
      await dispatch(updateEnterpriseProfile(formDataToSend));
  
      // تأخير لمدة ثانيتين قبل التوجيه
      setTimeout(() => {
        navigate("/enterprise/home");
        window.location.href = "/enterprise/home"
      }, 2000);  
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  
  
  
  return (
    <div className={`EnterpriseInformation_Container ${language}`} dir={language === 'ar' ? 'rtl' : 'ltr'}>
      <h2 className="EnterpriseInformation_textTitle">{texts.title}</h2>
      <form onSubmit={handleSubmit}>
     
       {/* Enterprise_Support  */}
      <div className='EnterpriseInformation_Enterprise_Support'>

         <div style={{color:'#000', fontSize:'20px', fontWeight:'700'}}>
            Customer Support Agent info
         </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.ownerFirstName} <span className="required">*</span></label>
          <input
            type="text"
            name="contact_person"
            style={{background:'#fff'}}
            placeholder={texts.placeholder.ownerFirstName}
            value={formData.contact_person}
            onChange={handleChange}
            required
          />
        </div>

          <div className="EnterpriseInformation_Section">
             <label>{texts.ownerspecialization} <span className="required">*</span></label>
            <input
             type="text"
             name="person_position"
             style={{background:'#fff'}}
             placeholder={texts.placeholder.ownerspecialization}
             value={formData.person_position}
             onChange={handleChange}
            
            />
          </div>

        </div>  

        <div className="EnterpriseInformation_Section">
          <label>{texts.enterpriseName} <span className="required">*</span></label>
          <input
            type="text"
            name="name"
            placeholder={texts.placeholder.enterpriseName}
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.email} <span className="required">*</span></label>
          <input
            type="email"
            name="email"
            placeholder={texts.placeholder.email}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.phone} <span className="required">*</span></label>
          <input
            type="tel"
            name="phone"
            placeholder={texts.placeholder.phone}
            value={formData.phone}
            onChange={handleChange}
         
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.website}</label> {/* Added website field */}
          <input
            type="url"
            name="website_link"
            placeholder={texts.placeholder.website}
            value={formData.website_link}
            onChange={handleChange}
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.about}</label>
          <RichTextEditor
             value={typeof formData.description === 'string' ? formData.description : ''} // تحقق من أن القيمة نصية
             onChange={handleQuillChange}
          />
        </div>
        {/* 
        <div className="EnterpriseInformation_Section" style={{marginTop:'25px'}}>
      <label>Location at google map </label>
         <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
          <GoogleMap
           mapContainerStyle={{ height: "400px", width: "100%" }}
           center={markerPosition}
           zoom={10}
           onLoad={onLoad}
          />
          </LoadScript>
    </div>
         */}



        <div className="EnterpriseInformation_Section" style={{marginTop:'15px'}}>
          <label>{texts.enterpriseTitle} <span className="required">*</span></label>
          <input
            type="text"
            name="specialization"
            placeholder={texts.placeholder.enterpriseTitle}
            value={formData.specialization}
            onChange={handleChange}
           
          />
        </div>

        <div className="EnterpriseInformation_Section">
          <label>{texts.address} <span className="required">*</span></label>
          <input
            type="text"
            name="address"
            placeholder={texts.placeholder.address}
            value={formData.address}
            onChange={handleChange}
            
          />
        </div>

        <div className="EnterpriseInformation_Section">
  <label>{texts.accreditation} </label>
  <input
    type="file"
    accept="image/*"
    onChange={handleFileChange}
  />
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
    {previewImage && <img src={previewImage} alt="Preview" style={{ maxWidth: '350px' }} />}
  </div>
</div>

      {/* 
        <div className="EnterpriseInformation_Section">
      <label>{texts.images}</label>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImagesChange}
      />
      <div className="image-preview">
        {mainImage && (
          <motion.div
            className="main-image"
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src={mainImage} alt="Main Preview"  />
          </motion.div>
        )}
        <motion.div
          className="thumbnails"
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {previewImages.map((image, index) => (
            <motion.div
              key={index}
              className="thumbnail"
              layout
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => handleMainImageSwap(index)}
            >
              <img src={image} alt={`Preview ${index}`} />
         
            </motion.div>
          ))}
        </motion.div>
      </div> 
    </div>
      */}
    <div className="EnterpriseInformation_Section_Button">
  <button type="submit" disabled={loading}>
    {loading ? (
      <>
        <span className="spinner"></span> {/* يمكن استخدام CSS لتصميم الـ Spinner */}
        {texts.loading} {/* رسالة التحميل */}
      </>
    ) : (
      <>
        <img src={Saveicon} alt="Saveicon" />
        {texts.submit}
      </>
    )}
  </button>


</div>
      
      </form>
        {/* عرض رسائل الخطأ أو النجاح */}
  {success && (
    <p className="success-message" style={{ color: "green" }}>
      {texts.successMessage || "تم الحفظ بنجاح!"}
    </p>
  )}
{error && (
  <p className="success-message" style={{ color: "red" }}>
    {typeof error === "string" ? error : error.msg || "An error occurred."}
  </p>
)}
    </div>
  );
};

export default EnterpriseInformationForm;
